import React from 'react';

import BoxStyled from '../../../../components/Box';
import DetectionContent from './modules/content';

function MobileDetection() {
  return (
    <BoxStyled p={2} pt={10.5}>
      <DetectionContent />
    </BoxStyled>
  )
}

export default MobileDetection;
