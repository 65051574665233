import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setHoveredMatch } from "../../../../../../store/slices/predictions";
import { INPUT_COMPARISON_AREAS } from "../../ComparisonContent";

const useHoverMatch = () => {
  const dispatch = useDispatch();

  const onHoverMatch = useCallback((match) => {
    const hoveredMatch = {};

    if (match)
      match.objects.forEach(objectPath => {
        const objectPathSplitted = objectPath.split('@');
        const index = parseInt(objectPathSplitted[0]) - 1;
        const id = +objectPathSplitted[1];

        hoveredMatch[INPUT_COMPARISON_AREAS[index]] = id;
      });

    dispatch(setHoveredMatch({ match: hoveredMatch }));
  }, []);

  return { onHoverMatch };
}

export default useHoverMatch;