import { useContext, useState } from "react";
import { StoreContext } from "../context/StoreContext";

const useWebcamDisplay = () => {
  const { displayWebcam } = useContext(StoreContext);
  const [showWebcam, setShowWebcam] = useState(false);

  const handleChangeDisplayWebcam = () => {
    displayWebcam.set(!showWebcam);
    setShowWebcam(!showWebcam);
  }

  return [showWebcam, handleChangeDisplayWebcam];
}

export default useWebcamDisplay;