import styled from 'styled-components';
import { breakpoints } from '../../../../../../components/MediaQueries/consts';

export const Wrapper = styled.div`
  position: relative;
`;

export const Buttons = styled.div`
  position: absolute;
  bottom: -1px;
  transform: translateY(100%);
  max-width: 170px;
  width: 100%;

  @media only screen and (max-width: ${breakpoints.table}px) {
    max-width: 100%;
    z-index: 1000;
  }
`;