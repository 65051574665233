import { createSlice } from '@reduxjs/toolkit';
import { VideoUtils } from '../../components/Video/VideoPrecapture/video-utils';
import { PREDICT_PRECISION_TIME, PRELOAD_TIME_STEP_SEC } from '../../components/Video/VideoPrecapture/videoframes-preloader';


const videoUtils = new VideoUtils();
const initialState = {
  cache: {},
  demandForPreload: {
    isPreloaded: false,
    toPreload: false,
    start: 0,
    end: 0
  },

  playbackTime: {
    start: 0,
    currentNormal: 0,
    currentTime: 0
  },

  duration: 0,
  soughtPlayback: 0,
  continuousPlaybackLimit: 0,
  latestRequestedFrameTime: 0,

  isPreloaded: false,
  isPlayRequested: false,
  shouldPlay: false,
  isSeeking: false,
};




export const trackingVideoSlice = createSlice({
  name: 'tracking_video',
  initialState,
  reducers: {

    setDuration: (state, action) => {
      state.duration = action.payload.duration;
    },


    setPlaybackTime: (state, action) => {
      state.playbackTime = videoUtils.calcPlaybackTime(
        action.payload, state
      );
      state.demandForPreload = videoUtils.getDemandForPreload({ ...state });
      state.isPreloaded = state.demandForPreload.isPreloaded; // временно
    },


    setSoughtPlayback: (state, action) => {
      const { soughtPlayback } = action.payload;
      state.soughtPlayback = soughtPlayback;


      const frameAtThePoint = videoUtils.findClosestEntry(
        state.cache, soughtPlayback
      );
      const desiredTime = {
        currentTime: soughtPlayback,
        start: frameAtThePoint ? state.playbackTime.start : soughtPlayback
      };
      state.playbackTime = videoUtils.calcPlaybackTime(
        desiredTime, state
      );
    },


    setLatestRequestedFrameTime: (state, action) => {
      state.latestRequestedFrameTime = action.payload.frameTimeKey;
    },


    addFramesToCache: (state, action) => {
      state.cache = { ...state.cache, ...action.payload };
    },


    addPredictionsToCache: (state, action) => {
      const { predicts } = action.payload;
      const { currentTime } = state.playbackTime;


      state.cache = { ...state.cache, ...predicts };
      const closestFrame = videoUtils.findClosestEntry(
        { ...state.cache }, currentTime
      );

      const isPreloaded = closestFrame?.prediction !== undefined;
      state.isPreloaded = isPreloaded;
      console.log('frames cached, isPreloaded', state.cache, isPreloaded);
    },


    cleanupStore: () => {
      return { ...initialState };
    },


    setPredictionsLimit: (state, action) => {
      state.continuousPlaybackLimit = action.payload.playbackLimit;
    },


    setPlayRequestedStatus: (state, action) => {
      state.isPlayRequested = action.payload.isPlayRequested;
      state.shouldPlay = videoUtils.getDemandForPlay({ ...state });
      state.demandForPreload = videoUtils.getDemandForPreload({ ...state });
    },


    setPreloadedStatus: (state, action) => {
      state.isPreloaded = action.payload.isPreloaded;
    },


    setSeekingStatus: (state, action) => {
      state.isSeeking = action.payload.isSeeking;
      state.shouldPlay = videoUtils.getDemandForPlay({ ...state });
      state.demandForPreload = videoUtils.getDemandForPreload({ ...state });
    },


    setBatchPreloadPoint: (state, action) => {
      state.demandForPreload = action.payload.preloadEndpoint;
    }
  },
});


export const { actions } = trackingVideoSlice;
export default trackingVideoSlice.reducer;