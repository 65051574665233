import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getPredictions } from '../../../../store/slices/predictions';

import { Battery } from '@3divi/baseui';
import ComparisonUploadPanel from './ComparisonUploadPanel';

export const INPUT_COMPARISON_AREAS = ["compare_left", "compare_right"];

function ComparisonContent() {
  const dispatch = useDispatch();
  const [files, setFiles] = useState({ compare_left: null, compare_right: null });

  useEffect(() => {
    if (Object.values(files).every(fileValue => fileValue))
      dispatch(getPredictions({ files: Object.values(files) }));
  }, [files]);

  const handleAddFile = useCallback((file, name) => {
    setFiles(prevState => ({ ...prevState, [name]: file }));
  }, [])

  return (
    <Battery borderRadius="4.5vw">
      {INPUT_COMPARISON_AREAS.map((name, index) => (
        <ComparisonUploadPanel onUpload={handleAddFile} key={name} index={index} name={name} />
      ))}
    </Battery>
  )
}

export default ComparisonContent
