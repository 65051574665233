import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../MediaQueries/consts';

const StyledError = styled.div`
  position: absolute;
  left: 50%;
  top: 4px;
  max-width: 400px;
  transform: translateX(-50%);
  padding: 12px 24px;
  margin: 0 auto;
  z-index: 99999999;
  border-radius: 4px;
  background: hsla(0,0%,100%,1);

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    max-width: 90%;
    width: 100%;
    text-align: center;
  }
`

const ErrorText = styled.div`
  color: #ff0000;
  font-family: 'Montserrat', 'IBM Plex Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
  z-index: 2;
`;

function ErrorBody(props) {
  const { error } = props;
  return (
    <StyledError>
      <ErrorText>{error}</ErrorText>
    </StyledError>
  );
}

export default ErrorBody;
