import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ROUTES } from './routes';

function Main() {
  return (
    <Switch>
      {ROUTES.map(route => (
        <Route key={route.path} path={route.path}>
          <route.component />
        </Route>
      ))}
      <Redirect to={ROUTES[0].path} />
    </Switch>
  )
}

export default Main;
