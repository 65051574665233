import { useCallback, useContext } from "react";
import { DropareaContext } from "../DropareaContext";

const useDroparea = () => {
  const values = useContext(DropareaContext);

  const handleOpenExplorer = useCallback((e) => {
    e.stopPropagation();
    values.areaRef.current && values.areaRef.current.click();
  }, [values.areaRef.current])

  return Object.assign({}, values, { onOpenExplorer: handleOpenExplorer });
};

export default useDroparea;