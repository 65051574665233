import styled from 'styled-components';


export const Videoplayer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: ${props => props.minWidth};
  min-height: ${props => props.minHeight};
  z-index: 12;
`;


export const Videoframe = styled.div`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  z-index: -1;
  top: 0;
  left: 0;
`;