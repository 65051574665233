import React from 'react';

import * as S from './components';
import UploadFileArea from './upload-file-area';
import { Grid } from '@material-ui/core';

function FilesUploadControlPanel({ getRef, children, innerContent, buttons, borderRadius }) {
  return (
    <S.Wrapper ref={getRef}>
      <Grid container justify="center" alignItems="center" direction="column">
        <UploadFileArea innerContent={innerContent} borderRadius={borderRadius}>
          {children}
        </UploadFileArea>
        {buttons && (
          <S.Buttons>
            {buttons}
          </S.Buttons>
        )}
      </Grid>
    </S.Wrapper>
  )
}

export default FilesUploadControlPanel
