import { TYPES_OF_SEGMENTATION } from "./useTypeOfSegmentation";
import { blendColors } from "../utils";

import useGenerateColorsBySegmentationType from "./useGenerateColorsBySegmentationType";

const useCreateSegmentationColors = (objects, segmentationType) => {
  const colors = useGenerateColorsBySegmentationType(objects, segmentationType);
  if (!colors) return null;

  let dictColors = { objectsColors: {}, segmentsColors: {} };

  if (!objects) return dictColors

  if (segmentationType === TYPES_OF_SEGMENTATION.semantic)
    dictColors = createSemanticSegmentationsColors(objects, colors);
  else
    dictColors = createIntsanceSegmentationsColors(objects, colors);

  return dictColors;
}

function createSemanticSegmentationsColors(objects, colors) {
  const objectsColors = {}, segmentsColors = {}, temp = {};
  let count = 0;

  objects.forEach(object => {
    const sameClassColor = temp[object.class];
    const color = sameClassColor ? sameClassColor : colors[count];
    objectsColors[object.id] = { color, className: object.class };

    const segments = object.segments;
    segments && segments.forEach(segmentId => {
      segmentsColors[segmentId] = {color: color};
    });

    if (!sameClassColor) {
      temp[object.class] = colors[count];
      count++;
    }
  });

  return { objectsColors, segmentsColors };
}

function createIntsanceSegmentationsColors(objects, colors) {
  const segmentsColors = {}, objectsColors = {};

  objects.forEach((object, i) => {
    objectsColors[object.id] = { color: colors[i], className: object.class };

    const segments = object.segments;
    segments && segments.forEach(segmentId => {
      const objectsHaveThisSegment = objects.filter(object => object.segments && object.segments.includes(segmentId));
      let segmentObject = object;

      if (objectsHaveThisSegment.length > 1) {
        segmentObject = objectsHaveThisSegment.find(object => object.segments && object.segments.length === 1);
      }

      if (segmentObject) {
        if (!segmentsColors[segmentId] && segmentObject.class === object.class)
          segmentsColors[segmentId] = colors[i];
      } else {
        const colorsToBlend = objectsHaveThisSegment.map(object => {
          const index = objects.findIndex((object2) => object.id === object2.id);
          return colors[index];
        });

        let mixedColors = blendColors(colorsToBlend[0], colorsToBlend[1]);
        if (colorsToBlend.length > 2) {
          for (let index = 2; index < colorsToBlend.length; index++) {
            const color = colorsToBlend[index];
            mixedColors = blendColors(mixedColors, color);
          }
        }

        segmentsColors[segmentId] = {color: mixedColors};
      }
    });
  });

  return { segmentsColors, objectsColors };
}

export default useCreateSegmentationColors;
