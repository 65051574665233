import { useSelector } from 'react-redux';
import { getPredictions } from '../store/selectors/predictions';


const REPORT_URL = process.env.REACT_APP_COLLECTOR_URL + 'api/report';


const useSendReport = ({ src }) => {
   const predictions = useSelector(getPredictions);
   const sendReport = async (description, onError) => {

      onError && onError('');
      try {
         const srcBlobImage = await fetch(src).then(r => r.blob());
         if (!srcBlobImage) {
            onError && onError('Cannot get last image');
            return;
         }

         const formData = new FormData();
         formData.append('image', srcBlobImage);
         formData.append('description', description);
         formData.append('model', window.location.hostname);
         formData.append('prediction', JSON.stringify(predictions[0]));

         const response = await fetch(REPORT_URL, {
            headers: { Accept: 'application/json' },
            method: 'POST',
            body: formData
         });

         if (!response.ok) throw new Error('Server is not responding');

         const contentType = response.headers.get('content-type');
         if (contentType && contentType.indexOf('application/json') !== -1) {
            const responseData = await response.json();
            if (responseData.Error) throw new Error(responseData.Error);
         }

      } catch (error) {
         onError && onError(`Sending Report Error: ${error.message}`);
         throw error.message;
      }
   };
   return sendReport;
}

export default useSendReport;