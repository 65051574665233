import React, { useState } from 'react';
import ReportIssueForm from './ReportIssueForm';
import ReportIssueButton from './ReportIssueButton';
import { useShowToggle, useSendReport } from '../../hooks/';
import ReportIssueSuccess from './ReportIssueSuccess/ReportIssueSuccess';


const EMPTY_DESCRIPTION_ERROR_TEXT = 'You must describe yor issue!';


function ReportIssue({ src }) {
  const [toOpenReportIssueForm, toggleShowReportIssueForm] = useShowToggle(false);
  const [isSentSuccessfully, toggleShowSuccessModal] = useShowToggle(false);

  const [isTryingToSend, setIsTryingToSend] = useState(false);
  const [issueSubjectText, setIssueSubjectText] = useState('');
  const [submitErrorText, setSubmitErrorText] = useState('');
  const [inputErrorText, setInputErrorText] = useState('');

  const sendReportHook = useSendReport({ src });
  const onSubmitError = errorText => setSubmitErrorText(errorText);


  const clearErrorsCloseForm = () => {
    toggleShowReportIssueForm();
    clearErrors();
  }


  const clearErrors = () => {
    setSubmitErrorText('');
    setInputErrorText('');
  }


  const trySendReport = async () => {
    setIsTryingToSend(true);
    await sendReportHook(issueSubjectText, onSubmitError);

    toggleShowSuccessModal();
    setIssueSubjectText('');
    clearErrorsCloseForm();
  }


  const onSubmitClick = async (e) => {
    e.preventDefault();
    if (issueSubjectText.trim().length === 0) {
      setInputErrorText(EMPTY_DESCRIPTION_ERROR_TEXT);
      return;
    }
    try {
      await trySendReport();
    } catch (errorText) {
      setSubmitErrorText(errorText);
    } finally {
      setIsTryingToSend(false);
    }
  }


  const onTextareaTextChange = (value) => {
    setIssueSubjectText(value);
    setInputErrorText('');
  }


  const onCloseForm = (e) => {
    e && e.preventDefault();
    clearErrorsCloseForm();
  }


  return (
    <>
      <ReportIssueButton
        onClick={toggleShowReportIssueForm}
      />
      <ReportIssueForm
        isOpen={toOpenReportIssueForm}
        onTextareaTextChange={onTextareaTextChange}
        onToggleForm={onCloseForm}
        onSendReport={onSubmitClick}
        inputErrorText={inputErrorText}
        textareaValue={issueSubjectText}
        submitErrorText={submitErrorText}
        tryingToSend={isTryingToSend}
      />
      <ReportIssueSuccess
        isOpen={isSentSuccessfully}
        onToggleModal={toggleShowSuccessModal}
      />
    </>
  )
}

export default ReportIssue;
