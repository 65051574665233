import React, { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMakePrediction, usePhotoSize } from '../../../../hooks';
import { useUploadFile } from '../../../../components/Droparea/hooks';
import { StoreContext } from '../../../../context/StoreContext';
import { getHoveredMatchByName, getMatches, getPredictions as getPredictionsSelector, getPredictionsStatus } from '../../../../store/selectors/predictions';

import Photo from '../../../../components/Photo';
import { Face } from '../../../../components/Objects';
import OverlappingPreloader from '../../../../components/OverlappingPreloader/OverlappingPreloader';
import { FACEMACHINE_THEME } from '@3divi/baseui';
import { DropareaContextProvider } from '../../../../components/Droparea/DropareaContext';
import FilesUploadControlPanel from '../detection/modules/files-upload-control-panel/FilesUploadControlPanel';
import FilesUploadControlButtons from '../detection/modules/files-upload-control-panel/FilesUploadControlButtons';

function ComparisonUploadPanel({ index, onUpload, name, ...others }) {
  const { error } = useContext(StoreContext);
  const [parentSize, setParentSize] = useState(null);

  const { onUploadFile, image } = useUploadFile({ onError: error.set });

  const predictions = useSelector(getPredictionsSelector);
  const status = useSelector(getPredictionsStatus);
  const prediction = useMakePrediction(predictions[index], image);
  const [imageSize, getimageSize] = usePhotoSize(image?.src, status.success);

  const matches = useSelector(getMatches);
  const hoveredMatchId = useSelector(getHoveredMatchByName(name));

  const getParentSize = useCallback((node) => {
    if (node !== null) {
      setParentSize({ width: node.clientWidth, height: node.clientHeight });
    }
  }, []);

  const handleUploadFile = (files, inputName) => {
    onUploadFile(files, inputName);
    onUpload(files[0], inputName);
  }

  const faces = prediction && prediction.objects.filter(el => el.class == "face");

  const samePersonCheck = (id) => {
    if (!matches) return false;

    return matches.some(match => {
      return match.is_similar &&
        match.objects.some(objectId => objectId === `${index + 1}@${id}`)
    });
  }

  const getBboxColor = (faceId) => {
    if (hoveredMatchId && faceId === hoveredMatchId) return 'yellow';

    return samePersonCheck(faceId) ? 'hsl(120deg 74% 51%)' : FACEMACHINE_THEME.button.secondary;
  }

  return (
    <DropareaContextProvider name={name} loading={status.progress} onUploadFile={handleUploadFile}>
      <FilesUploadControlPanel
        {...others}
        getRef={getParentSize}
        innerContent={image && (
          <Photo {...image} {...prediction} getRef={getimageSize} maxSize={parentSize}>
            {status.progress && <OverlappingPreloader />}
            {imageSize && faces && faces.map(el => (
              <Face
                key={`face-${el.id}`}
                face={el}
                boxColor={getBboxColor(el.id)}
                width={imageSize.width}
                height={imageSize.height}
                faces={faces}
              />
            ))}
          </Photo>
        )}
        buttons={<FilesUploadControlButtons />}
      />
    </DropareaContextProvider>
  )
}

export default ComparisonUploadPanel;
