import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { StoreContext } from '../../context/StoreContext';
import { getPredictionsError } from '../../store/selectors/predictions';
import ErrorBody from '.';


const Error = ({ onError = null }) => {
  const { error } = useContext(StoreContext);
  const predictionsError = useSelector(getPredictionsError);

  const errorText = predictionsError || error.value; // || 'demo error';

  useEffect(
    () => { errorText && onError && onError() },
    [error, predictionsError]
  );




  /* const textRef = useRef('');
  const isTextPresent = useRef(false);
  console.log('ERROR', error, predictionsError); */


  /* if (errorText) {
    textRef.current = errorText;
    isTextPresent.current = true;

    console.log("VIDEO ERROR SET TO", errorText);

  } */



  return (
    <>
      {errorText && (
        <ErrorBody error={errorText} />
      )}
    </>
  )
}


export default Error;