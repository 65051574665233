import React, { useMemo } from "react";
import styled from 'styled-components';
import { indent } from "./consts";
import { GridContextProvider } from "./GridContext";

const GRID_DIRECTIONS_TYPES = {
  row: "row",
  column: "column"
}

const GridContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  ${props => props.direction === GRID_DIRECTIONS_TYPES.row ? `
    flex-direction: row;
  ` : `
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `}

  ${props => props.offset && `
    width: calc(100% + ${props.offset}px);
    margin: -${props.offset * 0.5}px;
  `}
`;

const Container = ({
  children,
  spacing = 2,
  direction = GRID_DIRECTIONS_TYPES.row,
  style,
}) => {
  const contextValue = useMemo(() => ({ spacing }), [spacing]);

  return (
    <GridContainer
      direction={direction}
      offset={indent * spacing}
      style={style}
    >
      <GridContextProvider value={contextValue}>{children}</GridContextProvider>
    </GridContainer>
  );
};

export default Container;
