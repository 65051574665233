import { PREDICT_PRECISION_TIME, PRELOAD_TIME_STEP_SEC, SAFE_PLAYBACK_TIMESPAN } from "./videoframes-preloader";


export class VideoUtils {
  getOrCreateElement(tagName, id) {
    let elem = document.getElementById(id);
    if (elem) return elem;

    // console.log('creating new ', elem);
    elem = document.createElement(tagName);
    elem.id = id;
    return elem;
  }


  getDemandForPlay({ isPlayRequested, isSeeking }) {
    return isPlayRequested && !isSeeking;
  }


  getDemandForPreload({ playbackTime,
    isPlayRequested, isSeeking, cache }) {

    if (isSeeking) return false;

    // console.log('-----------------------------------', cache);

    // const keyToFind = Math.min(
    //   playbackTime.currentTime + SAFE_PLAYBACK_TIMESPAN,
    //   duration
    // );

    // console.log('CURRENT TIME IN DEMANDER', playbackTime.currentTime);

    const cacheBreakRange = this.findNextCacheBreakRange(
      playbackTime.currentTime, cache
    );
    const { start, end } = cacheBreakRange;



    const toPreload = start - playbackTime.currentTime <
      SAFE_PLAYBACK_TIMESPAN && playbackTime.start !== end;

    /* if (start - playbackTime.currentTime < SAFE_PLAYBACK_TIMESPAN) {
      console.log('out of cache coming, range is', start, end);
    } */
    // console.log('condition',
    //   start - playbackTime.currentTime <
    //   PREDICT_PRECISION_TIME,
    //   playbackTime.start !== end);

    return {
      toPreload,
      ...cacheBreakRange
    };
  }


  calcPlaybackTime({ currentTime, start }, { playbackTime, duration }) {
    const currentNormal = duration !== 0 ?
      (currentTime || playbackTime.currentTime) / duration :
      0;
    return {
      start: start || playbackTime.start,
      currentTime: currentTime || playbackTime.currentTime,
      currentNormal
    }
  }


  findClosestEntry(videoCache, keyToFind) {
    let existingKey;
    let minDelta;

    for (const key in videoCache) {
      const deltaTime = Math.abs(key - keyToFind);
      if (!existingKey || deltaTime < minDelta) {
        existingKey = key;
        minDelta = deltaTime;
      }
    }
    // console.log('min delta time', minDelta);
    if (minDelta <= PREDICT_PRECISION_TIME) return videoCache[existingKey];
    return undefined;
  }


  findNextCacheBreakRange(currentTime, videoCache) {
    let isPreloaded = false;
    let prevKey = 0;
    let start = null;
    let end;

    // console.log('BREAK RANGE');


    // console.log('looking up cache-------------------', videoCache);


    for (const key in videoCache) {
      const numKey = +key;

      if (Math.abs(numKey - currentTime) <
        PREDICT_PRECISION_TIME) isPreloaded = true;
      if (numKey <= currentTime) continue;

      // console.log('keys: prev, next', numKey, prevKey);
      prevKey = numKey;

      // console.log('condition, !start, diff', !start, (numKey - prevKey) > PREDICT_PRECISION_TIME);

      if (!start ^ (numKey - prevKey) > PREDICT_PRECISION_TIME) continue;

      if (!start) start = prevKey;
      else end = numKey;

      if (end) break;
    }
    if (!start) start = currentTime;
    return {
      start,
      end: end || start + PRELOAD_TIME_STEP_SEC,
      isPreloaded
    };
  }


  /* findClosestNumKey(object, keyToFind, precision = PREDICT_PRECISION_TIME) {
    let result = Number.MAX_SAFE_INTEGER;
    let ITERATIONS = 0; // FOR PERFOMANSE TEST


    for (const key in object) {
      const diff = Math.abs(keyToFind - key);
      if (diff < precision && diff < result) result = key;
      ITERATIONS++;
    }
    console.log('value of _ found in _ steps', keyToFind, ITERATIONS);
    return {
      found: result != Number.MAX_SAFE_INTEGER,
      result
    };
  } */


  filterMissingFramesAndPredicts(frames, videoCache) {
    const missingFrames = {};
    const missingPredicts = {};

    for (const key in frames) {
      const framePresent = videoCache[key];
      const predictPresent = framePresent && videoCache[key].prediction;

      if (!framePresent) missingFrames[`${key}`] = {
        frameData: frames[`${key}`].dataUri
      };
      if (!predictPresent) missingPredicts[`${key}`] = frames[`${key}`];
    }
    return {
      frames: missingFrames,
      predictions: missingPredicts
    }
  }


  requestForPrediction(frameData, requestCanceller) {
    const frameFile = new File(
      [frameData.blob], 'videoframe.png', { type: 'image/png' }
    );
    return {
      files: [frameFile],
      requestCanceller,
    }
  }
}