import React from 'react';
import styled from 'styled-components';

import { Preloader } from '@3divi/baseui';

const Overlapping = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: hsla(0, 0%, 0%, 0.7);
  z-index: 100;
`;

const PreloaderStyled = styled(Preloader)`
  flex-grow: 0;
`;

function OverlappingPreloader() {
  return (
    <Overlapping>
      <PreloaderStyled size="l" padding="8px 16px" />
    </Overlapping>
  )
}

export default OverlappingPreloader;
