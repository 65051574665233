import React from 'react';
import { floatToPercent } from '../../../../../../../../utils';

import EmotionsAttributes from './EmotionsAttributes';
import AnglesAttributes from './AnglesAttributes';
import FaceAttributes from './FaceAttributes';
import FaceConfidence from './FaceConfidence';

function FaceMetaAttributes({ face }) {
  return (
    <>
      <FaceConfidence faceConfidence={floatToPercent(face.confidence)} />
      <FaceAttributes faceMeta={face.face_meta} />
      <EmotionsAttributes emotions={face.face_meta.emotions} />
      <AnglesAttributes angles={face.angles} />
    </>
  )
}

export default FaceMetaAttributes;
