import React from 'react';
import { usePhotoSize, useWebcamDisplay } from '../../../../../../../hooks';
import { useDroparea } from '../../../../../../../components/Droparea/hooks';
import { useMediaQueries } from '../../../../../../../components/MediaQueries/hooks';

import { GridLayout } from '../../../../../../../components/Grid/GridLayout';
import Content from '../../../../../../../layers/Content';
import Footer from '../../../../../../../layers/Footer';
import { Battery, Button, Divider } from '@3divi/baseui';
import TakePhoto from '../../../../../../../components/TakePhoto';
import FilesUploadControlPanel from '../../files-upload-control-panel/FilesUploadControlPanel';
import { Grid } from '@material-ui/core';
import Photo from '../../../../../../../components/Photo';
import DetectionPhotoNotification from '../../DetectionPhotoNotification';
import DetectionFaces from '../../DetectionFaces';
import OverlappingPreloader from '../../../../../../../components/OverlappingPreloader';

function FileUploadScreen({ image, prediction, maxSize, faces, loading }) {
  const [showWebcam, changeDisplayWebcam] = useWebcamDisplay();
  const { name, onUploadFile, onOpenExplorer } = useDroparea();
  const [imageSize, getImageSize] = usePhotoSize(image?.src);
  const { isTable, isMobile, isAndroid, isiOS } = useMediaQueries();
  const isAndroidOriOS = isAndroid || isiOS;

  return (
    <>
      <TakePhoto onUploadFile={onUploadFile} inputName={name} isOpen={showWebcam} changeWebcamStatus={changeDisplayWebcam} />
      <GridLayout rows={`3fr ${isMobile ? '1fr' : 'auto'}`}>
        <Content>
          <FilesUploadControlPanel
            borderRadius="5vh"
            buttons={
              isTable && (
                <Grid container justify="center">
                  {isAndroidOriOS && (
                    <Button onClick={onOpenExplorer} variant="contained" color="default" size="m" iconLeft="upload" text="Select photo" />
                  )}
                  {!isAndroidOriOS && (
                    <Battery direction="row">
                      <Button onClick={onOpenExplorer} elastic variant="contained" color="default" size="m" iconLeft="upload" text="Select photo" />
                      <Divider size={3} direction="Y" color="primary" />
                      <Button onClick={changeDisplayWebcam} elastic variant="contained" color="default" size="m" iconLeft="camera" text="Take photo" />
                    </Battery>
                  )}
                </Grid>
              )
            }
          >
            {image && (
              <Photo
                {...image}
                {...(prediction ? prediction : {})}
                getRef={getImageSize}
                maxSize={maxSize}
              >
                {prediction && faces.length > 1 && <DetectionPhotoNotification />}
                {loading && <OverlappingPreloader />}
                {faces && imageSize && <DetectionFaces imageSize={imageSize} faces={faces} />}
              </Photo>
            )}
          </FilesUploadControlPanel>
        </Content>
        {isMobile && (
          <Footer>
            {isAndroidOriOS && (
              <Button elastic onClick={onOpenExplorer} variant="contained" color="default" size="m" iconLeft="upload" text="Select photo" />
            )}
            {!isAndroidOriOS && (
              <Battery direction="column">
                <Button onClick={onOpenExplorer} variant="contained" color="default" size="m" iconLeft="upload" text="Select photo" />
                <Divider size={3} color="primary" />
                <Button onClick={changeDisplayWebcam} variant="contained" color="default" size="m" iconLeft="camera" text="Take photo" />
              </Battery>
            )}
          </Footer>
        )}
      </GridLayout>
    </>
  )
}

export default FileUploadScreen;
