import { TimeTracker } from "./time-tracker";
import { VideoUtils } from "./video-utils";
import { VideoframeExtractor } from "./videoframe-extractor";

export class VideoframesCutter extends TimeTracker {
  static _extractor = new VideoframeExtractor();

  _canvas = null;
  _video = null;

  _frames = {};
  _endSeconds = 0;
  _resolve = () => { };



  constructor(video) {
    super();
    this._video = video;
    this._canvas = new VideoUtils().getOrCreateElement(
      'canvas', 'tracking-canvas'
    );

    this._canvas.width = "640px";
    this._canvas.height = "360px";
    this._canvas.hidden = true;

    this._subscribeToPlay();
    this._subscribeToPause();
    TimeTracker._addStopHandler(() => this._frames = {});
  }


  async cutFramesInRange(startSeconds, endSeconds) {
    if (endSeconds <= startSeconds) throw new RangeError();

    this._endSeconds = endSeconds;
    this._video.currentTime = startSeconds;

    return new Promise(resolve => {
      // console.log(this._video.currentTime)

      this._resolve = resolve;
      this._video.play();
    })
  }


  _subscribeToPlay() {
    this._video.addEventListener(
      'play',
      () => this._tick(
        null,
        () => this._video.currentTime >= this._endSeconds,
        () => this._video.pause(),
        () => this._extractFrame()
      ),
      false
    );
  }


  _subscribeToPause() {
    this._video.addEventListener(
      'pause',
      () => {
        this._resolve(this._frames)
        this._frames = {};
      }
    )
  }


  _extractFrame() {
    const { _frames, _video } = this;
    const { _extractor } = VideoframesCutter;
    const [blob, dataUri] = _extractor.extractVideoframe({
      canvas: this._canvas,
      video: _video
    });
    _frames[`${_video.currentTime}`] = {
      blob, dataUri
    };
  }
}
