import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { capitalize, floatToPercent } from '../../../../../../../../utils';
import { getFaceMetaItemDisplayed } from '../../../../../../../../store/selectors/face-sdk';

import GridContainer, { GridItem } from '../../../../../../../../components/Grid';
import FaceAttributeSection from './FaceAttributeSection';
import FaceAttributeRow from './FaceAttributeRow';

function EmotionsAttributes({ emotions }) {
  const displayEmotions = useSelector(getFaceMetaItemDisplayed('emotions'));

  const maxEmotion = useMemo(() => {
    if (!emotions) return null;
    const maxConfidence = Math.max(...emotions.map(emotion => emotion.confidence));
    return emotions.find((el) => el.confidence === maxConfidence);
  }, [emotions]);

  if (!emotions || !displayEmotions) return null;

  return (
    <GridItem>
      <FaceAttributeSection divider>
        <GridContainer direction="column" spacing={1.25}>
          {emotions.map(emotion => (
            <GridItem key={emotion.value}>
              <FaceAttributeRow
                color={maxEmotion.value === emotion.value ? "success" : "default"}
                name={capitalize(emotion.value)}
                value={`${floatToPercent(emotion.confidence)}%`}
              />
            </GridItem>
          ))}
        </GridContainer>
      </FaceAttributeSection>
    </GridItem >
  )
}

export default EmotionsAttributes;
