import React from 'react';
import styled from 'styled-components';

const Item = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};
  padding: 0 ${props => props.padding * 8}px;

  ${props => props.divider && `
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      heigth: 1px;
      bottom: ${-props.padding * 8 * 0.5}px;
      left: 0;
      border-bottom: 1px solid ${props.dividerColor};
    }
  `}
`;

function ListItem({ children, justify = "space-between", padding, divider, dividerColor, className }) {
  return (
    <Item justify={justify} divider={divider} dividerColor={dividerColor} padding={padding} className={className}>
      {children}
    </Item>
  )
}

export default ListItem;
