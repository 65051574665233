import styled from 'styled-components';



export const DotColor = styled.span`
  display: block;
  min-width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid rgba(0,0,0,.08);
  background-color: ${props => props.color};
`;

export const LegendColor = styled.div`
  display: flex;
  align-items: center;
  min-width: 46px;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.lineHeight}px;

  ${props => props.capitalize && `
    &:first-letter {
      text-transform: capitalize
    }
  `}
`;

export const Space = styled.div`
  padding: ${props => props.padding};
`;