import React, { createContext, useMemo, useRef, useState } from "react";

const DropareaContext = createContext({
  hovered: false,
  areaRef: null,
  name: '',
  loading: false,
  disabled: false,
  onHovered: () => { },
  onUploadFile: () => { }
});

const DropareaContextProvider = ({ children, onUploadFile, name, loading, disabled }) => {
  const [hovered, setHovered] = useState(false);
  const areaRef = useRef(null);

  const values = useMemo(() => ({
    name,
    hovered,
    areaRef,
    loading,
    disabled,
    onHovered: setHovered,
    onUploadFile
  }), [hovered, areaRef.current, name, loading, disabled, onUploadFile]);

  return (
    <DropareaContext.Provider value={values}>{children}</DropareaContext.Provider>
  )
};

export { DropareaContext, DropareaContextProvider };
