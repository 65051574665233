
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQueries } from '../../../../components/MediaQueries/hooks';
import { clearPredictions } from '../../../../store/slices/predictions';

import DesktopDetectionPage from './DesktopDetectionPage';
import MobileDetectionPage from './MobileDetectionPage';

function Detection() {
  const { isDesktop } = useMediaQueries();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearPredictions({}));
  }, []);

  return (
    <>
      {isDesktop && <DesktopDetectionPage />}
      {!isDesktop && <MobileDetectionPage />}
    </>
  )
}


export default Detection;