import { useCallback, useState } from "react";


const useShowToggle = () => {
  const [isOpen, setOpen] = useState(false);

  const toggleShowModal = useCallback(
    () => setOpen(!isOpen),
    [isOpen]
  );
  return [isOpen, toggleShowModal];
};


export default useShowToggle;