import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../components/MediaQueries/consts';

const Wrap = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 32px;
  left: 0;

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    width: calc(100vw - 32px);
    height: 100%;
    position: relative;
    bottom: 0;
  }
`;

function Footer({ children }) {
  return (
    <Wrap>
      {children}
    </Wrap>
  )
}

export default Footer;
