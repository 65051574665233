import React from 'react';
import styled from 'styled-components';

import { Box } from '@3divi/baseui';
import { breakpoints } from '../../components/MediaQueries/consts';

const Wrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: ${props => props.position};
  max-width: 250px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 10;
  overflow-y: auto;

  @media only screen and (max-width: ${breakpoints.table}px) {
    position: relative;
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

function Sidebar({ children, position = 'flex-start', spacing = 0 }) {
  return (
    <Wrap position={position}>
      <Box p={spacing}>
        {children}
      </Box>
    </Wrap>
  )
}

export default Sidebar;
