import React from 'react';

import { Box, P } from '@3divi/baseui';
import Footer from '../../../../layers/Footer';
import Content from '../../../../layers/Content';
import Sidebar from '../../../../layers/Sidebar';
import SidebarSection from '../../../../components/SidebarSection';
import Settings from './modules/settings';
import DetectedPersonInfo from './modules/sidebar/detected-person-info';
import DetectionContent from './modules/content';

function DesktopDetection() {
  return (
    <>
      <Content>
        <DetectionContent />
      </Content>
      <Sidebar spacing={2}>
        <Box pb={2}>
          <SidebarSection title="Settings">
            <Settings />
          </SidebarSection>
        </Box>
        <DetectedPersonInfo />
      </Sidebar>
      <Footer>
        <P color="secondary">Drag-and-drop the photo here to find the faces</P>
      </Footer>
    </>
  )
}

export default DesktopDetection;
