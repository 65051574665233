import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useDroparea } from '../../components/Droparea/hooks';

import Img from '../../components/Img';
import Text from '../../components/Text';
import { getPredictionsStatus } from '../../store/selectors/predictions';
import { P } from '@3divi/baseui';
import DropArea from '../../components/Droparea';

const TextWrap = styled.div`
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.text.defaultContrast};
  box-shadow: -8px 0 0 1px hsla(216,40%,10%,1),8px 0 0 1px hsla(216,40%,10%,1);
`;

function UploadArea({ isFileUploaded }) {
  const { hovered } = useDroparea();
  const { progress } = useSelector(getPredictionsStatus);
  return (
    <>
      <DropArea />
      {isFileUploaded && !progress ?
        <TextWrap><P>Select another photo or drag here</P></TextWrap>
        :
        <>
          <Img onArea={hovered} loading={progress} />
          <Text loading={progress} />
        </>
      }
    </>
  )
}

export default UploadArea;
