import React, { useContext } from 'react';
import '../../App.css';

import Menu from '../../layers/Menu';
import { StoreContext } from '../../context/StoreContext';
import Main from './Main';
import { MenuContents } from './MenuContents';


export const BACKGROUND_SIZE = { width: 200, height: 200 };
export const BACKGROUND_SPACE = { left: 101, top: 101 };


function Default() {
  return (
    <>
      <Menu>
        <MenuContents />
      </Menu>

      <Main />
    </>
  )
}

export default Default;
