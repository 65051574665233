import { useSelector } from 'react-redux';
import { getPredictionsCache } from '../../../store/selectors/tracking-video';
import { VideoUtils } from './video-utils';


const usePredictionsCache = () => {
  const videoCache = useSelector(getPredictionsCache);
  const videoUtils = new VideoUtils();
  return (playbackTime) => videoUtils.findClosestEntry(videoCache, playbackTime);
};

export default usePredictionsCache;
