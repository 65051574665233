import React from 'react';
import FileUploadScreen from './FileUploadScreen';
import UploadedFileScreen from './UploadedFileScreen';

function MobileDetectionContent(props) {
  return (
    <>
      {!props.image && <FileUploadScreen {...props} />}
      {props.image && <UploadedFileScreen {...props} />}
    </>
  )
}

export default MobileDetectionContent;
