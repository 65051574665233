import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import Icon from '../Img/Icon';

const Container = styled.button`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid hsla(0,0%,100%,.32);
  border-radius: 8px;
  background-color: ${props => props.theme.background.secondary};
  padding: 4px 16px 4px 0;
  margin: 0 8px;
  z-index: 4;
  color: white;

  &:hover {
    border-color: hsla(0,0%,100%,.48);
  }
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 4px;
`;

const Text = styled.div`
  font-family: 'Montserrat', 'IBM Plex Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
  margin: 0 4px;
`;

function Button(props) {
  const {
    onArea,
    text,
    onClick,
    icon,
    iconColor = "#FFF",
    iconEnabled = true,
    loading,
    ...other
  } = props;
  return (
    <Container onClick={onClick} {...other}>
      { iconEnabled &&
        <IconContainer>
          <Icon width="24" height="24" padding="8" icon={icon ? icon : (onArea || loading) ? "download" : "people"} color={iconColor} />
        </IconContainer>
      }
      <Text>{text}</Text>
    </Container>
  );
}

export default Button;
