import React from 'react';

import { Box, FACEMACHINE_THEME, Typography } from '@3divi/baseui';
import styled from 'styled-components';

const Wrap = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

function FaceAttributeRow({ name, value, color = "default" }) {
  return (
    <Wrap>
      <Typography size={14} color={color}>{name}</Typography>
      <Typography size={14} color={color}>{value}</Typography>
    </Wrap>
  )
}

export default FaceAttributeRow;