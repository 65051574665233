import React, { Fragment } from 'react';
import styled, { keyframes } from 'styled-components';


const fadeWave = keyframes`
  from, to {
    border-color: hsla(0,0%,100%, ${props => props.opacity});
  }

  50% {
    border-color: hsla(0,0%,100%,0.32);
  }
`;

const scaleWave = keyframes`
  from, to {
    transform: scale(1);
  }

  50% {
    transform: scale(1.025);
  }
`;

const Border = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid white;
  margin-left: ${props => - (props.space.left + props.i * 40)}px;
  margin-top: ${props => - (props.space.top + props.i * 40)}px;
  border-color: hsla(0,0%,100%, ${props => props.opacity});
  animation: ${props => props.isLoading ? scaleWave : fadeWave} ${props => props.isLoading && props.isImg ? 'infinite' : '1'} .88s ease-in;
  width: ${props => props.size.width + 80 * props.i + 'px'};
  height: ${props => props.size.height + 80 * props.i + 'px'};
  border-radius: ${props => 48 + props.i + props.i * props.borderRadius + 'px'};
  animation-delay: ${props => `${88 * props.i}ms`};
  animation-play-state: ${props => !props.isLoading ^ props.isImg ? "running" : "paused"};
`;


function Background({ waves = 24, size, space, borderRadius, ...others }) {

  // const { isImg, isLoaded, isLoading } = others;
  // console.log('IMG, ed, ing', isImg, isLoaded, isLoading);
  // if (isImg ^ !isLoading) console.log('PLAYING');


  const bg = Array.from({ length: waves }, (_, i) => i + 1);
  return (
    <Fragment>
      {bg.map(i =>
        <Border
          size={size}
          space={space}
          borderRadius={borderRadius}
          key={i.toString()}
          i={i}
          opacity={
            waves - i * 2 <= 0 ? 0 : (waves - i * 2) / 100
          } {...others}
        />)}
    </Fragment>
  );
}

export default Background;
