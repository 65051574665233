import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearPredictions, getPredictions } from '../../../../../../store/slices/predictions';
import { useMakePrediction } from '../../../../../../hooks';
import { useUploadFile } from '../../../../../../components/Droparea/hooks';
import { useMediaQueries } from '../../../../../../components/MediaQueries/hooks';
import { getPredictions as getPredictionsSelector, getPredictionsStatusType } from '../../../../../../store/selectors/predictions';
import { StoreContext } from '../../../../../../context/StoreContext';
import { DropareaContextProvider } from '../../../../../../components/Droparea/DropareaContext';

import DesktopDetectionContent from './DesktopDetectionContent';
import MobileDetectionContent from './mobile-detection-content';

const INPUT_NAME = "detection";
const WINDOW_SIZE = { width: document.documentElement.clientWidth, height: document.documentElement.clientHeight };

function DetectionContent() {
  const { isDesktop } = useMediaQueries();

  const { setError } = useContext(StoreContext);
  const dispatch = useDispatch();

  const { file, image, onUploadFile } = useUploadFile({ onError: setError });
  const predictions = useSelector(getPredictionsSelector);
  const prediction = useMakePrediction(predictions[0], image);
  const statusProgress = useSelector(getPredictionsStatusType('progress'));

  useEffect(() => {
    if (file) {
      dispatch(clearPredictions());
      dispatch(getPredictions({ files: [file] }));
    }
  }, [file]);

  const faces = prediction && prediction.objects.filter(el => el.class == "face");

  return (
    <DropareaContextProvider name={INPUT_NAME} loading={statusProgress} onUploadFile={onUploadFile}>
      {isDesktop && <DesktopDetectionContent maxSize={WINDOW_SIZE} image={image} prediction={prediction} faces={faces} loading={statusProgress} />}
      {!isDesktop && <MobileDetectionContent maxSize={WINDOW_SIZE} image={image} prediction={prediction} faces={faces} loading={statusProgress} />}
    </DropareaContextProvider>
  )
}

export default DetectionContent;
