import React, { Fragment } from 'react';
import { useHoverMatch } from './hooks';

import SidebarSection from '../../../../../components/SidebarSection';
import ScrollBar from '../../../../../components/ScrollBars';
import PredictionSmall from '../../../../../components/PredictionSmall';
import List, { ListItem } from '../../../../../components/List';
import { DEMO_DARK } from '@3divi/baseui';

function SameMatches({ sameMatches }) {
  const { onHoverMatch } = useHoverMatch();

  return (
    <SidebarSection title="Similar people">
      <ScrollBar maxHeight="42vh">
        <List gutters={0.5} indent={1}>
          {sameMatches.map((match, i) => (
            <Fragment key={i}>
              <ListItem divider={i < sameMatches.length - 1} dividerColor={DEMO_DARK.border.default} padding={2}>
                <PredictionSmall onHover={onHoverMatch} {...match} images={match.images} />
              </ListItem>
            </Fragment>
          ))}
        </List>
      </ScrollBar>
    </SidebarSection>
  )
}

export default SameMatches;
