import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { StoreContext } from '../../context/StoreContext';
import { getPredictionsError } from '../../store/selectors/predictions';
import { Helmet } from 'react-helmet';

import Background from '../../components/Background';
import Error from '../../components/Error';
import Main from './Main';
import Menu from './menu';

const BACKGROUND_SIZE = { width: 300, height: 300 };
const BACKGROUND_SPACE = { left: 150, top: 150 };
function FaceSdk() {
  const { error } = useContext(StoreContext);
  const errorPredict = useSelector(getPredictionsError);
  const errorMessage = errorPredict || error.value;

  return (
    <>
      <Helmet>
        <title>3DiVi Face SDK Demo</title>
      </Helmet>
      <Menu />
      {errorMessage && (
        <Error error={errorMessage} />
      )}
      <Main />
      {/* <Background
        size={BACKGROUND_SIZE}
        space={BACKGROUND_SPACE}
        borderRadius={30}
        loading={status.value.progress}
        loaded={status.value.success}
        isImg={!predictions.value}
      /> */}
    </>
  )
}

export default FaceSdk;
