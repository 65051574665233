import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@3divi/baseui';
import GridContainer, { GridItem } from '../Grid';
import { Fragment } from 'react';
import Divider from '../Divider';

const NavRow = styled.div`
  border-radius: 2px;
`;

function NavList({ routers }) {
  const location = useLocation();

  return (
    <GridContainer spacing={0} direction="column">
      {routers.map((route, i) => {
        const isActive = checkPath(location.pathname, route.path);
        return (
          <Fragment key={route.path}>
            <GridItem>
              <NavLink to={route.path}>
                <NavRow >
                  <Button
                    color={isActive ? 'primary' : 'default'}
                    variant={isActive ? 'contained' : 'clear'}
                    elastic
                    size="s"
                    borderRadius="8px"
                    hoverColor={!isActive && "light"}
                    text={route.displayName}
                  />
                  {i < routers.length - 1 && <Divider />}
                </NavRow>
              </NavLink>
            </GridItem>
          </Fragment>
        )
      })}
    </GridContainer>
  )
}

function checkPath(elLink, currentPath) {
  return currentPath === elLink;
}

export default NavList;
