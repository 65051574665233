import styled from 'styled-components';


export const Collapse = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px;
  background-color: #fff;

  & > * {
    opacity: 0.7;
  }
`;

export const CollapseIcon = styled.div`
  display: inline-block;
  height: 24px;
  ${props => props.isFull && `
    transform: rotate(180deg);
  `}
`;