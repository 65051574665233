export const getFileUploadedStatus = state => state.predictions.isFileUploaded;
export const getPredictions = state => state.predictions.predictions;
export const getPredictionsStatus = state => state.predictions.status;
export const getPredictionsStatusType = type => state => getPredictionsStatus(state)[type];
export const getMatches = state => state.predictions.matches;
export const getPredictionsError = state => state.predictions.error;
export const getLegendSelectedClasses = state => state.predictions.legendSelectedClasses;
export const getImages = state => state.predictions.images;
export const isLegendClassSelected = (className) => (state) => getLegendSelectedClasses(state).includes(className);
export const getSelectedPredictionIndex = state => state.predictions.selectedPredictionIndex;
export const getWasIteraction = state => state.predictions.wasIteraction;
export const getHoveredMatch = state => state.predictions.hoveredMatch;
export const getHoveredMatchByName = (name) => state => getHoveredMatch(state)[name];