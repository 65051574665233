import React from "react";
import styled from 'styled-components';

const Svg = styled.div`
  padding: ${props => props.padding + "px"};
`;

const ICON = {
  people: "M40.423 52c16.204 0 29.907 10.705 34.423 25.43h-4.21C66.27 64.95 54.392 56 40.423 56c-13.969 0-25.847 8.95-30.213 21.43H6C10.516 62.704 24.22 52 40.423 52zM40 3c11.046 0 20 8.954 20 20s-8.954 20-20 20-20-8.954-20-20S28.954 3 40 3zm0 4c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16S48.837 7 40 7z",
  download: "M39.9860797,32 L40.08,32.129 L51,43.2017577 L48.2663587,46 L42,39.584 L42,69 L38,69 L38,39.523 L31.7336413,45.9384793 L29,43.140237 L39.9860797,32 Z M41,15 C47.5434914,15 53.3530302,18.1424245 57.0018329,23.0004901 C65.8370992,23.0005432 73,30.163779 73,39 C73,47.500604 66.3708878,54.4528958 58.0002994,54.9692396 L58,55 L48,55 L48,51 L57,51 C63.627417,51 69,45.627417 69,39 C69,32.372583 63.627417,27 57,27 C56.3040255,27 55.6218894,27.059249 54.9586309,27.1738977 C52.2177545,22.2957509 46.9937592,19 41,19 C32.163444,19 25,26.163444 25,35 C25,35.3247174 25.0096731,35.6471755 25.0307613,35.9666996 C23.6840859,35.3502103 22.1406093,35 20.5,35 C20.2246973,35 19.9521298,35.0098614 19.6827846,35.0287285 C19.4577146,35.0097052 19.2299907,35 19,35 C14.581722,35 11,38.581722 11,43 C11,46.9928337 13.9251507,50.3024619 17.7493881,50.9028204 L18.0000487,50.9381123 L18,51 L32,51 L32,55 L18,55 L18.0005348,54.9589703 C11.8407497,54.451074 7,49.2908458 7,43 C7,36.372583 12.372583,31 19,31 C19.8061892,31 20.5938102,31.0795003 21.3554508,31.2310887 C23.1170986,21.9874518 31.2425459,15 41,15 Z",
  camera: "M52.449 7.875l4.98 6.25h22.408v55H.163v-55h22.408l4.98-6.25h24.898zm-4.98 5H32.531l-4.98 6.25H5.143v45h69.714v-45H52.45l-4.98-6.25zM40 25.429c8.284 0 15 6.688 15 14.938s-6.716 14.94-15 14.94c-8.284 0-15-6.69-15-14.94S31.716 25.43 40 25.43zm0 4.98c-5.523 0-10 4.458-10 9.958s4.477 9.96 10 9.96 10-4.46 10-9.96c0-5.5-4.477-9.959-10-9.959zM66.5 25a2.5 2.5 0 110 5 2.5 2.5 0 010-5z",
  collapse: "M21 16v2H9v-2h12zM7 8l-3 4 3 4H5l-3-4 3-4h2zm14 3v2H9v-2h12zm0-5v2H9V6h12z",
}

function Icon(props) {
  const {
    width,
    height,
    padding,
    icon,
    viewBox = "0 0 80 80",
    color = "#FFF"
  } = props;
  return (
    <Svg padding={padding}>
      <svg width={width} height={height} preserveAspectRatio="xMidYMid meet" x="0" y="0" viewBox={viewBox}>
        <path
          fill={color}
          fillRule="evenodd"
          d={ICON[icon]}
        />
      </svg>
    </Svg>
  )
}

export default Icon;
