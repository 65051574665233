import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Selectors from '../../../store/selectors/tracking-video';
import * as Preloader from './videoframes-preloader';
import usePredictionsCache from './usePredictionsCache';
import { actions } from '../../../store/slices/tracking-video';
import axios from 'axios';
import { VideoframesCutter } from './videoframes-cutter';
import { VideoUtils } from './video-utils';
import { getPredictions } from '../../../store/slices/predictions';


let lastRequestID = 0;
let requestsStory = {};

let lastToken;
const createToken = () => {
  const t = axios.CancelToken.source();
  console.log('new token is same', t == lastToken);
  lastToken = t;
  return t;
}
let cancelToken = createToken();


export const cancelRequestsForPredicts = () => {
  requestsStory[lastRequestID] = true;
  lastRequestID++;
  cancelToken.cancel();
  cancelToken = createToken();
};


export const useFramesPreload = (video) => {
  const dispatch = useDispatch();
  const getFrameCache = usePredictionsCache();


  const apiRequests = useRef({});

  const preloader = useMemo(() => new Preloader.VideoframePreloader());
  const videoUtils = useMemo(() => new VideoUtils());
  const framesCutter = useMemo(
    () => video && new VideoframesCutter(video),
    [video]
  );

  const { currentTime } = useSelector(Selectors.getPlaybackTime);
  const isSeeking = useSelector(Selectors.getSeekingStatus);
  // const shouldPlay = useSelector(Selectors.getShouldPlayStatus);
  const { toPreload, start, end } = useSelector(Selectors.getDemandForPreloadInfo);



  // useEffect(() => console.log('NEW  STRT in useVideo', start), [start]);
  useEffect(() => {
    if (!isSeeking) console.warn('new current time', currentTime);
  }, [isSeeking])
  const filesToFail = useRef(0); // временно для искусственной ошибки 



  const handlePredictResponce = (newFrameKey, isCancelled) => {
    if (isCancelled) {
      console.log('cancelled frames keep coming in');
      return;
    }
    dispatch(actions.setPredictionsLimit({
      playbackLimit: newFrameKey,
    }))
  };


  const handleBatchResponce = ({
    predicts, lastKey, anyRejected }) => { // async

    // console.log('receiving BATCH, it is rejected', anyRejected);
    if (anyRejected) {
      console.log('rejection dropped');
      return;
    }

    dispatch(actions.addPredictionsToCache({
      predicts, lastKey
    }));
  }



  const awaitBatch = () => {
    console.warn('AWAITING for batch - start, requests', start, apiRequests.current);

    const requestID = +lastRequestID;
    requestsStory[requestID] = false;
    const getCancelStatus = () => requestsStory[requestID];

    preloader.getPredictionsForFrames(
      { ...apiRequests.current },
      handlePredictResponce,
      getCancelStatus
    ).then(
      handleBatchResponce
    );
    apiRequests.current = {};
  };


  const requestPredictionForFrame = (timeKey, frameData) => {
    // console.log('GOTTEN frames, ', time, obj);

    const apiPayload = videoUtils.requestForPrediction(
      frameData, dispatch, cancelToken
    );
    const promise = dispatch(getPredictions(apiPayload));

    // frameFile = currentSeconds.toString().includes('6') && filesToFail.current < 15 ?
    //   new File([new Blob([1, 2, 3], { type: 'image/png' })], 'videoframe.png', { type: 'image/png' }) :
    //   frameFile;
    // if (frameFile.size === 3) filesToFail.current++; // для тестовых ошибок

    apiRequests.current[timeKey] = {
      promise, frameData,
    };
  };


  const onFramesSlice = frames => {
    console.log('big hook, frames cut', frames);

    const framesToCache = {};
    for (const time in frames) {

      const frameCache = getFrameCache(time);
      if (frameCache) continue;

      const key = `${time}`;
      framesToCache[key] = frames[key];
      requestPredictionForFrame(key, frames[key]);
    }
    dispatch(actions.addFramesToCache(framesToCache));
    console.warn('requests sent', frames);
    awaitBatch();
  }


  useEffect(
    () => {
      if (!toPreload || !framesCutter) return;
      dispatch(actions.setPlaybackTime({
        start: end
      }));

      framesCutter.cutFramesInRange(
        start, end
      ).then(
        onFramesSlice
      ).catch(
        error => console.log(error)
      );
    },
    [video, toPreload]
  )
};


export default useFramesPreload;