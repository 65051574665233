import React from 'react';
import { useSelector } from 'react-redux';
import { getFaceMetaDisplayed } from '../../../../../../../../store/selectors/face-sdk';

import { GridItem } from '../../../../../../../../components/Grid';
import FaceAttributeRow from './FaceAttributeRow';
import FaceAttributeSection from './FaceAttributeSection';

function FaceConfidence({ faceConfidence }) {
  const faceMetaDisplayed = useSelector(getFaceMetaDisplayed);
  const displayDivider = Object.entries(faceMetaDisplayed)
    .filter(([key, value]) => key !== "bbox" && key !== "keypoints")
    .some(([key, value]) => value)

  return (
    <GridItem>
      <FaceAttributeSection divider={displayDivider}>
        <FaceAttributeRow name="Face confidence" value={faceConfidence ? `${faceConfidence}%` : '—'} />
      </FaceAttributeSection>
    </GridItem>
  )
}

export default FaceConfidence;
