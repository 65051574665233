import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { initLegendClasses } from '../../store/slices/predictions';
import { makeRGBAString, removeDuplicatesInObjectsArrayByFieldName } from '../../utils';

import Divider from '../Divider';
import { ItemRow } from './LegendItemRow';
import Scrollbars from 'rc-scrollbars';

export const LegendList = ({ objectsColors, isFull }) => {
  const dispatch = useDispatch();

  const legendColors = useMemo(() =>
    removeDuplicatesInObjectsArrayByFieldName(Object.values(objectsColors), 'className'),
    [objectsColors.length]
  );

  useEffect(() => {
    const legendSelectedClasses =
      Object.values(legendColors).map(classObject => classObject.className);
    dispatch(initLegendClasses({ legendSelectedClasses }));
  }, []);

  return (
    <Scrollbars autoHeight autoHeightMax={400}>
      {legendColors.map(({ color, className }, id) => (
        <Fragment key={`${className}_${makeRGBAString(color)}`}>

          <ItemRow color={makeRGBAString(color)}
            isFull={isFull}
            className={className} id={id} />
          <Divider />
        </Fragment>
      ))}
    </Scrollbars>
  )
}
