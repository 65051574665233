import React from 'react';
import { DETECTION_ROUTE, VERIFICATION_ROUTE, IDENTIFIACATION_ROUTE } from '../routes';

import { Box, Divider, Typography } from '@3divi/baseui';
import Menu from '../../../components/Menu';
import Logo from '../../../components/Logo';
import NavList from '../../../components/Nav';

function DesktopMenu() {
  return (
    <Menu>
      <Logo />
      <Box px={0.5} py={2}>
        <Typography color="default" weight={500} align="center" spacing={2} size={17} transform="uppercase">Face SDK Demo</Typography>
      </Box>
      <Box px={0.5} pb={0.5}>
        <Divider />
        <NavList routers={[DETECTION_ROUTE, VERIFICATION_ROUTE, IDENTIFIACATION_ROUTE]} />
      </Box>
    </Menu>
  )
}

export default DesktopMenu;
