import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../components/MediaQueries/consts';

const MenuWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  
  @media only screen and (max-width: ${breakpoints.table}px) {
    width: 100%;
  }
`;

function Menu({ children }) {
  return (
    <MenuWrap>
      {children}
    </MenuWrap>
  )
}

export default Menu;
