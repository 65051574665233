import React from 'react';

import PhotoCreator from '../PhotoCreator';
import { Modal } from '@3divi/baseui';

function TakePhoto({ changeWebcamStatus, onUploadFile, inputName, isOpen }) {

  const onMakePhoto = (files) => {
    onUploadFile(files, inputName);
  }

  return (
    <Modal fixed={640} close={changeWebcamStatus} isOpen={isOpen}>
      <PhotoCreator onMakePhoto={onMakePhoto} changeWebcamStatus={changeWebcamStatus} />
    </Modal>
  )
}

export default TakePhoto;
