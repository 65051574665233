import React from 'react';
import { useSelector } from 'react-redux';
import Photo from '../../components/Photo';
import { useMakePrediction, usePhotoSize } from '../../hooks';
import { getPredictions, getPredictionsStatus } from '../../store/selectors/predictions';
import Prediction from "./Prediction";


const WINDOW_SIZE = {
  width: document.documentElement.clientWidth,
  height: document.documentElement.clientHeight
};


const PhotoPrediction = ({ image }) => {
  const status = useSelector(getPredictionsStatus);
  const predictions = useSelector(getPredictions);

  const prediction = useMakePrediction(predictions[0], image);
  const [imageSize, getImageSize] = usePhotoSize(image?.src, status.success);


  return (
    <Photo {...prediction} getRef={getImageSize} maxSize={WINDOW_SIZE}>
      {imageSize && status.success &&
        <Prediction prediction={prediction} imageSize={imageSize} />
      }
    </Photo>
  );
}


export default PhotoPrediction;