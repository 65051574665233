import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../components/MediaQueries/consts';

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  z-index: 11;

  @media only screen and (max-width: ${breakpoints.table}px) {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    box-sizing: border-box;
    overflow: hidden;
  }
`;

function Content({ children, className }) {
  return (
    <Wrap className={className}>
      {children}
    </Wrap>
  )
}

export default Content;
