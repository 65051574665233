import React from 'react';
import styled from 'styled-components';

import { Panel } from '@3divi/baseui';

const StyledMsg = styled.div`
  position: absolute;
  min-width: 220px;
  box-sizing: border-box;
  transform: translate(100%, 0);
  right: 0;
  top: 0;
  z-index: 999;
`;

function Msg({ children, getRef }) {
  return (
    <StyledMsg ref={getRef}>
      <Panel borderRadius={1} color="primary">
        {children}
      </Panel>
    </StyledMsg>
  );
}

export default Msg;
