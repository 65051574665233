import React from 'react';

import { KeyPoint, BBox } from '../Elements';
import BBoxHeader from '../Elements/BBoxHeader';

export const bboxToObject = (bbox, width, height) => {
  return {
    x: bbox[0] * width,
    y: bbox[1] * height,
    width: (bbox[2] - bbox[0]) * width,
    height: (bbox[3] - bbox[1]) * height
  };
};


function GenericObject({ object, boxColor, width, height }) {
  let objects = [];

  if (object.keypoints) {
    objects = objects.concat(Object.entries(object.keypoints).map(([name, keypoint]) => (
      <KeyPoint
        pointColor={boxColor}
        key={`object-${object.id}-keypoint-${name}`}
        x={keypoint.proj[0] * width}
        y={keypoint.proj[1] * height}
        radius={4}
      />
    )));
  }

  if (object.bbox) {
    objects = [
      ...objects,
      <BBox borderColor={boxColor} key={`object-${object.id}-bbox`} {...bboxToObject(object.bbox, width, height)}>
        <BBoxHeader zIndex={object.class_id} bgColor={boxColor}>{object.class}{object.confidence && `: ${roundingANumber(object.confidence)}`}</BBoxHeader>
      </BBox>
    ]
  }

  return (
    <>
      {objects}
    </>
  )
}

function roundingANumber(number) {
  return parseFloat(number).toFixed(2)
}

export default GenericObject;
