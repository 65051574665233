/**
  * Removes duplicates in an array of objects by field name
  * 
  * @param {object[]} objectsArray
  * @param {string} fieldName - The name of the field tha should not be repeated
  * @returns {Object[]}
  */
const removeDuplicatesInObjectsArrayByFieldName = (objectsArray, fieldName) => {
  const result = [];
  const hashUniqueValues = {};

  objectsArray.forEach(object => {
    const fieldValue = object[fieldName];

    if (!hashUniqueValues[fieldValue]) {
      hashUniqueValues[fieldValue] = true;
      result.push(object);
    }
  });

  return result;
}

export default removeDuplicatesInObjectsArrayByFieldName;