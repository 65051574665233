import React, { useState, useContext, useEffect, useRef, useMemo, useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideoControlPanel from './VideoControlPanel';
import FrameScreen from './FrameScreen';
import * as Views from './views';
import { actions } from '../../store/slices/tracking-video';
import { useFramesPreload } from './VideoPrecapture/useFramesPreload';
import { cancelRequestsForPredicts } from './VideoPrecapture/useFramesPreload';
import { VideoframesCutter } from './VideoPrecapture/videoframes-cutter';
import { DEFAULT_MAX_H, DEFAULT_MAX_W } from './VideoPrecapture/videoframe-extractor';
import { TimeTracker } from './VideoPrecapture/time-tracker';
import { getStateTest } from '../../store/selectors/tracking-video';
import { Battery } from '@3divi/baseui';



const DEFAULT_WIDTH = 640;
const DEFAULT_HEIGHT = 360;

function VideoBody({ videoSrc }) {
  // const { setError } = useContext(StoreContext);
  // const { pushFile, cleanUploadQueue } = useUploadBuffer(1);

  const dispatch = useDispatch();
  const [firstFrame, setFirstFrame] = useState(null);
  const [currentFrame, setCurrentFrame] = useState('');

  const ref = useRef(null);
  const trackerVideoRef = useRef(null);
  const toPlayRef = useRef(false);


  const frameSize = useMemo(
    () => ({
      width: firstFrame?.size.width || DEFAULT_WIDTH,
      height: firstFrame?.size.height || DEFAULT_HEIGHT,
    }),
    [firstFrame?.size]
  );


  useEffect(
    () => () => {
      // console.log('video src CHANGED, new', videoSrc);
      togglePlay(false);
      cancelRequestsForPredicts();
      TimeTracker.stopAll();
      dispatch(actions.cleanupStore());
    },
    [videoSrc]
  );


  const onFrameInit = useCallback(
    (frame) => setFirstFrame(frame),
    []
  );


  const togglePlay = (toStop) => {
    if (typeof toStop === 'boolean' && toStop)
      toPlayRef.current = false;
    else toPlayRef.current = !toPlayRef.current;

    dispatch(actions.setPlayRequestedStatus({
      isPlayRequested: toPlayRef.current,
    }));
  };


  return (
    videoSrc && (
      <Views.Videoplayer
        minWidth={`${frameSize.width}px`}
        minHeight={`${frameSize.height}px`}
        id={'videoplayer'}
      >
        <Battery
          direction={'column'}
          borderRadius='8px'//{`${({ theme }) => theme.spacing}px`}
        >
          <Views.Videoframe
            width={`${frameSize.width}px`}
            height={`${frameSize.height}px`}
          >
            <video
              ref={trackerVideoRef}
              src={videoSrc}
              hidden={true}
            ></video>

            <FrameScreen
              src={videoSrc}
              togglePlay={togglePlay}
              onLoaded={onFrameInit}
              frameSize={frameSize}
            />
          </Views.Videoframe>

          <VideoControlPanel
            playerRef={ref}
            trackerRef={trackerVideoRef}
            onClick={togglePlay}
          />
        </Battery>
      </Views.Videoplayer>
    )
  );
}

export default VideoBody;
