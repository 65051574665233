import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Prediction from '../../templates/Default/Prediction';
import usePredictionsCache from './VideoPrecapture/usePredictionsCache';
import OverlappingPreloader from '../OverlappingPreloader';
import { actions } from '../../store/slices/tracking-video';
import { getPlaybackTime, getPlayRequestedStatus, getPreloadedStatus } from '../../store/selectors/tracking-video';


const VideoPrediction = ({ frameSize }) => {
  const dispatch = useDispatch();

  const isPlayRequested = useSelector(getPlayRequestedStatus);
  const isPreloaded = useSelector(getPreloadedStatus);
  const { currentTime } = useSelector(getPlaybackTime);

  const { prediction, frameData } = usePredictionsCache()
    (currentTime) || {
    undefined, undefined
  };

  // console.log('playing playing playing');


  // useEffect(
  //   () => {
  //     dispatch(actions.setPreloadedStatus({
  //       isPreloaded: frameData !== undefined
  //     }));
  //   },
  //   [frameData, isPlayRequested]
  // );


  return (
    <>
      {prediction && <Prediction prediction={prediction} imageSize={frameSize} />}
      {isPlayRequested && !isPreloaded && <OverlappingPreloader />}
    </>
  );
}



export default VideoPrediction;