import React from 'react';
import styled from 'styled-components';

const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

function Image({ src }) {
  return (
    <ImageStyled src={src} />
  )
}

export default Image;
