import React from 'react';
import identification_image from '../../../../images/verification_1_n.png';
import { useMediaQueries } from '../../../../components/MediaQueries/hooks';

import Image from '../../../../components/Image';
import { Button, DEMO, Typography, Divider, Box } from '@3divi/baseui';
import { Grid } from '@material-ui/core';
import * as S from './components';
import Content from '../../../../layers/Content';
import BoxStyled from '../../../../components/Box';

function ComparisonAll() {
  const { isMobile, isTable } = useMediaQueries();
  const isSmallDevice = isMobile || isTable;

  return (
    <BoxStyled p={2} pt={10.5}>
      <Content>
        <Grid container alignItems="center" spacing={5} direction="column">
          <Grid item>
            <S.ImageWrapper>
              <Image src={identification_image} />
            </S.ImageWrapper>
          </Grid>
          <Grid item container alignItems="center" direction="column" spacing={5}>
            {!isMobile && (
              <Grid item>
                <Typography align="center" weight={400} color="default" size={isSmallDevice ? 32 : 40} as="h1">1:N Identification</Typography>
              </Grid>
            )}
            <Grid item>
              <S.TextWrapper>
                {isMobile && (
                  <Typography height={24} align="center" color="secondary" size={16}>
                    The function allows you to find similar faces in the database.
                    Search over 100M faces in 1s*
                  </Typography>
                )}
                {!isMobile && (
                  <>
                    <Typography height={isSmallDevice ? 28 : 32} align="center" color="secondary" size={isSmallDevice ? 18 : 24}>
                      The function allows you to find similar faces in the database.
                 </Typography>
                    <Typography height={isSmallDevice ? 28 : 32} align="center" color="secondary" size={isSmallDevice ? 18 : 24}>Search over 100M faces in 1s*</Typography>
                  </>
                )}
              </S.TextWrapper>
            </Grid>
            <Grid container xs={12} item>
              <S.ButtonWrapper>
                <Button elastic as="a" href="https://docs.facesdk.3divi.com/docs/getting_started/?from=face_sdk_demo" target="_blank" rel="nofollow noopener" variant="contained" color={DEMO.text.success} size="xl" text={<Typography size="l" align="center" color="default">Get Started</Typography>} />
              </S.ButtonWrapper>
            </Grid>
            <Grid item container justify="center">
              <S.Footer>
                {!isMobile && (
                  <Box pb={2}><Divider /></Box>
                )}
                <Typography align="center" size={isSmallDevice ? 13 : 16} color="disabled">* 3DiVi Face SDK allows to search over 100M faces in 1.2s</Typography>
              </S.Footer>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </BoxStyled>
  )
}

export default ComparisonAll
