import React from 'react';
import styled from 'styled-components';

import { Box, Button, Divider, P, Panel, Typography } from '@3divi/baseui';
import Image from '../../../../../../../../../components/Image';
import { GridLayout } from '../../../../../../../../../components/Grid/GridLayout';
import { Grid } from '@material-ui/core';
import FaceAttributeRow from '../../../../sidebar/detected-person-info/face-meta-attributes/FaceAttributeRow';
import BoxStyled from '../../../../../../../../../components/Box';

export const ImageWrap = styled.div`
  ${props => `
    width: ${props.width}px;
    height: ${props.height}px;
    border: 2px solid ${props.active ? '#ede35a' : 'transparent'};
    border-radius: ${props.borderRadius * props.theme.spacing}px;
  `}

  overflow: hidden;
`;

function PersonInfoPanel({ thumb, active, confidence, liveness, gender, mask, age, emotion, emotions, isSmall, onClick }) {
  return (
    <Panel width="max-content" color="primary" borderRadius={1}>
      <Box py={1}>
        <Grid container spacing={1} direction={isSmall ? 'row' : 'column'} wrap="nowrap">
          <Grid item container spacing={1}>
            <Grid item>
              <Box pl={1}>
                <ImageWrap active={active} width={70} height={70} borderRadius={1}>
                  <Image src={thumb} />
                </ImageWrap>
              </Box>
            </Grid>
            <Grid item>
              <Box pr={1}>
                <GridLayout rows="2.5fr 1fr">
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography color="secondary" spacing={1} weight={400} size={13}>CONFIDENCE</Typography>
                      <P>{confidence}%</P>
                    </Grid>
                    {isSmall && liveness && (
                      <Grid item>
                        <Typography color="secondary" spacing={1} weight={400} size={13}>{liveness.value.toUpperCase()}</Typography>
                        <P>{liveness.confidence}%</P>
                      </Grid>
                    )}
                  </Grid>
                  <P>
                    {gender || ''}
                    {age ? `, ${age}${emotion ? ', ' : ''}` : ''}
                    {emotion || ''}
                  </P>
                </GridLayout>
              </Box>
            </Grid>
          </Grid>
          {isSmall && (
            <Grid>
              <BoxStyled pr={1}>
                <Button style={{ height: '100%' }} onClick={onClick} variant="contained" color="default" borderRadius="8px" iconLeft="dropdown" />
              </BoxStyled>
            </Grid>
          )}
          {!isSmall && (
            <>
              {(mask || liveness) && (
                <Grid item>
                  <Divider />
                </Grid>
              )}
              <Grid item container spacing={1} direction="column">
                {mask && (
                  <Grid item>
                    <Box px={1}>
                      <FaceAttributeRow name={mask.value} value={`${mask.confidence}%`} />
                    </Box>
                  </Grid>
                )}
                {liveness && (
                  <Grid item>
                    <Box px={1}>
                      <FaceAttributeRow name={liveness.value} value={`${liveness.confidence}%`} />
                    </Box>
                  </Grid>
                )}
              </Grid>
              {emotions && (
                <Grid item>
                  <Divider />
                </Grid>
              )}
              {emotions && (
                <Grid item container spacing={1} direction="column">
                  {emotions.map(emotion => (
                    <Grid key={emotion.value} item>
                      <Box px={1}>
                        <FaceAttributeRow
                          color={emotion.dominant ? "success" : "default"}
                          name={emotion.value}
                          value={`${emotion.confidence}%`}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Panel>
  )
}

export default PersonInfoPanel;
