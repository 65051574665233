import React from 'react';
import { floatToPercent } from '../../utils';
import styled from 'styled-components';

import { Box, FACEMACHINE_THEME, P, Typography } from '@3divi/baseui';
import { ListItem } from '../../components/List';

const Wrap = styled.div`
  width: 100%;

  ${props => props.hover && `
    cursor: pointer;
    &:hover * {
      color: ${props.theme.text.defaultContrast};
    }
  `}
`

const Images = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageWrap = styled.div`
  width: 82px;
  height: 87px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

function PredictionSmall({ confidence, id, objects, images, onHover }) {

  const handleMouseEnter = () => {
    onHover && onHover({ objects });
  }

  const handleMouseLeave = () => {
    onHover && onHover(null);
  }

  return (
    <Wrap hover={onHover} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {images && (
        <Images py={0.7}>
          {images.map((image, i) => (
            <ImageWrap key={`${i}_${image}`}>
              <Image src={`data:image/jpeg;base64,${image}`} />
            </ImageWrap>
          ))}
        </Images>
      )}
      <Typography size={13} color={FACEMACHINE_THEME.text.tertiaryContrast}>
        <ListItem>
          <span>Confidence</span>
          <span>{floatToPercent(confidence)}%</span>
        </ListItem>
      </Typography>
    </Wrap>
  )
}

export default PredictionSmall
