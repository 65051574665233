import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getScales } from '../../utils';
import { breakpoints } from '../MediaQueries/consts';
import { useMediaQueries } from '../MediaQueries/hooks';

const DEFAULT_SIZE = 62;

const WrapImage = styled.div`
  position: relative;
  z-index: 11;
  ${props => props.maxScale === 'X' && `
    max-width: 100%;
    width: ${DEFAULT_SIZE}vw;
  `}
  ${props => props.maxScale === 'Y' && `
    max-height: 100%;
    height: ${DEFAULT_SIZE}vh;
  `}

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    ${props => props.maxScale === 'X' && `
      max-width: 100%;
      width: calc(100vw - 16px);
    `}
    ${props => props.maxScale === 'Y' && `
      max-height: 100%;
      height: 53vh;
    `}
  }
`;

const StyledPhoto = styled.img`
  ${props => props.maxScale === 'X' && `
    width: 100%;
    height: auto;
  `}
  ${props => props.maxScale === 'Y' && `
    width: auto;
    height: 100%;
  `}
  display: block;
  margin: 0 auto;
`;

function Photo({ src, maxSize, getRef, originalSize, children }) {
  const [imageScales, setImageScales] = useState(null);
  const { isDesktop } = useMediaQueries();

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (originalSize && maxSize)
        setImageScales(getScales(originalSize.width, originalSize.height, maxSize, DEFAULT_SIZE / 100));
    });
  }, []);

  useEffect(() => {
    if (originalSize && maxSize)
      setImageScales(getScales(originalSize.width, originalSize.height, maxSize, DEFAULT_SIZE / 100));
  }, [originalSize, maxSize]);


  let maxScale = imageScales && getMaxScaleOrientation({ ...imageScales });
  if (!isDesktop)
    maxScale = originalSize.width < originalSize.height ? 'Y' : 'X';

  return (
    maxScale && (
      <WrapImage maxScale={maxScale}>
        <StyledPhoto onLoad={getRef} ref={getRef} maxScale={maxScale} src={src} />
        {children}
      </WrapImage>
    )
  );
}

function getMaxScaleOrientation({ vertical, horizontal }) {
  return vertical < horizontal ? 'Y' : 'X';
}

export default Photo;
