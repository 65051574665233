import styled from 'styled-components';
import { breakpoints } from '../../../../components/MediaQueries/consts';

export const ImageWrapper = styled.div`
  width: 82vh;
  margin: -22% 0;

  @media only screen and (max-width: ${breakpoints.table}px) {
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  white-space: nowrap;

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    white-space: pre-wrap;
    max-width: 300px;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 180px;

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    width: 100%;
  }
`;

export const Footer = styled.div`
  max-width: 80%;
  width: 100%;

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    max-width: 300px;
  }
`;