import { useState } from "react";
import { bytesToSize, createImage } from "../../../utils";
import { isVideoFile, isImageFile } from "../../../utils/checkFile";
import ReactPlayer from 'react-player';


const useUploadFile = ({ onError }) => {
  const [file, setFile] = useState(null);
  const [inputName, setInputName] = useState(null);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);


  const _fileValidation = (file) => {
    let error = "";

    if (file && !(isVideoFile(file) || isImageFile(file)))
      error = "Only image or video can be uploaded";
    else if (file && +bytesToSize(file.size, 2) > 15)
      error = "Large file size";

    return error;
  }


  const onUploadFile = async (files, inputName) => {
    if (!files) return;
    setImage(null);
    setVideo(null);


    try {
      const file = files[0];
      const error = _fileValidation(file);
      if (error) throw new Error(error);

      if (isVideoFile(file)) {
        const src = window.URL.createObjectURL(file);

        if (!ReactPlayer.canPlay(src))
          throw new Error("Can't play this video format");

        setVideo({ src });
      } else {
        const image = await createImage(file);

        setImage({
          src: image.src,
          originalSize: { width: image.width, height: image.height },
        });
      }

      // if (!file.isFrame)
      //   setVideo(null);
      setInputName(inputName);
      setFile(file);
    } catch (error) {
      onError(error.message);
    }
  };

  const onClearFile = () => {
    setFile(null);
    setImage(null);
    setVideo(null);
  }


  return {
    file,
    inputName,
    image,
    video,
    onUploadFile,
    onClearFile,
  }
}


export default useUploadFile;