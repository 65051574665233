import React from 'react';
import { useWebcamDisplay } from '../../../../../../hooks';
import { useDroparea } from '../../../../../../components/Droparea/hooks';
import { useMediaQueries } from '../../../../../../components/MediaQueries/hooks';

import { Battery, Divider, Button } from '@3divi/baseui';
import TakePhoto from '../../../../../../components/TakePhoto';

function FilesUploadControlButtons() {
  const { isAndroid, isiOS } = useMediaQueries();
  const isAndroidOriOS = isAndroid || isiOS;
  const [showWebcam, changeDisplayWebcam] = useWebcamDisplay();
  const { areaRef, name, onUploadFile } = useDroparea();

  const handleOpenExplorer = (e) => {
    e.stopPropagation();
    areaRef.current && areaRef.current.click();
  }

  return (
    <>
      <TakePhoto onUploadFile={onUploadFile} inputName={name} isOpen={showWebcam} changeWebcamStatus={changeDisplayWebcam} />
      {isAndroidOriOS && (
        <Button elastic variant="contained" color="default" onClick={handleOpenExplorer} size="m" iconLeft="upload" text="Select photo" />
      )}
      {!isAndroidOriOS && (
        <Battery direction="column">
          <Button variant="contained" color="default" onClick={handleOpenExplorer} size="m" iconLeft="upload" text="Select photo" />
          <Divider size={3} color="primary" />
          <Button onClick={changeDisplayWebcam} variant="contained" color="default" size="m" iconLeft="camera" text="Take photo" />
        </Battery>
      )}
    </>
  )
}

export default FilesUploadControlButtons;
