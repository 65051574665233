import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLegendClassSelected } from '../../store/selectors/predictions';
import { toggleLegendClass } from '../../store/slices/predictions';
import { Space, Text, DotColor, LegendColor } from './LegendItem';



export const ItemRow = ({ color, isFull, className, id }) => {
  const dispatch = useDispatch();
  const onColorClicked = e => dispatch(toggleLegendClass({ className }));

  const selected = useSelector(isLegendClassSelected(className));
  const selectColor = selected ? (color) : 'transparent';

  return (
    <LegendColor className='legend-item' onClick={onColorClicked} id={id} >
      <DotColor color={selectColor} />
      {isFull && (
        <Space padding="0 8px">
          <Text capitalize>{className}</Text>
        </Space>
      )}
    </LegendColor>
  )
};