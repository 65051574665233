import React from 'react';
import { useSelector } from 'react-redux';
import { getPredictions, getSelectedPredictionIndex } from '../../../../../../../store/selectors/predictions';
import styled from 'styled-components';

import SidebarSection from '../../../../../../../components/SidebarSection';
import FaceMetaAttributes from './face-meta-attributes';
import { Box, Typography } from '@3divi/baseui';
import Divider from '../../../../../../../components/Divider';
import GridContainer, { GridItem } from '../../../../../../../components/Grid';
import DetectedFace from './DetectedFace';

const DetectedPersonHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function DetectedPersonInfo() {
  const selectedPredictionIndex = useSelector(getSelectedPredictionIndex);
  const predictions = useSelector(getPredictions);

  if (!predictions[0] || predictions[0].objects.length === 0) return null;

  const allPersonsCount = predictions[0].objects.length;
  const selectedPerson = predictions[0].objects[selectedPredictionIndex];

  if (!selectedPerson?.face_meta) return null;

  return (
    <SidebarSection
      header={
        <DetectedPersonHeader>
          <Typography transform="uppercase" spacing={1} weight={500} color="secondary" size={12}>Detected person</Typography>
          {allPersonsCount > 1 && (
            <Typography color="secondary" weight={500} size={14}>
              {selectedPredictionIndex + 1}/{allPersonsCount}
            </Typography>
          )}
        </DetectedPersonHeader>
      }
    >
      <Box py={1.5}>
        <GridContainer direction="column" spacing={1.5}>
          <GridItem>
            <DetectedFace allPersonsCount={allPersonsCount} thumb={selectedPerson.thumb} />
          </GridItem>
          <GridItem>
            <Divider />
          </GridItem>
          <FaceMetaAttributes face={selectedPerson} />
        </GridContainer>
      </Box>
    </SidebarSection>
  )
}

export default DetectedPersonInfo;
