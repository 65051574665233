import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Canvas = styled.canvas`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
`;

function Mask({
  segmentId, segmentColors, width, height }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      initCanvas();
    }
  }, [canvasRef.current, width, height, segmentColors]);

  const initCanvas = async () => {
    const ctx = canvasRef.current.getContext('2d');
    const imageMask = await createImage(segmentId);
    ctx.drawImage(imageMask, 0, 0, width, height);

    const maskImageData = ctx.getImageData(0, 0, width, height);
    makeMask(maskImageData, segmentColors);
    ctx.putImageData(maskImageData, 0, 0);
  }

  return (
    <Canvas ref={canvasRef} width={width} height={height} />
  )
}

function createImage(imageSrc) {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = `data:image/png;base64, ${imageSrc}`;
  })
}

function makeMask(imageData, segmentColors) {
  for (let i = 0; i < imageData.data.length; i += 4) {

    const rgbaColor = segmentColors[imageData.data[i]];
    if (rgbaColor) {
      imageData.data[i] = rgbaColor.color[0];
      imageData.data[i + 1] = rgbaColor.color[1];
      imageData.data[i + 2] = rgbaColor.color[2];
      imageData.data[i + 3] = 140;
    }
    else makePngTransparent(imageData.data, i);
  }
}

function makePngTransparent(imageData, index) {
  imageData[index] = 0;
  imageData[index + 1] = 0;
  imageData[index + 2] = 0;
  imageData[index + 3] = 0;
}

export default Mask;
