import React from 'react';
import styled from 'styled-components';

const Header = styled.header`
  position: absolute;
  top: -14px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  pointer-events: all;
  z-index: ${props => props.zIndex};

  &:hover {
    z-index: 10000;
    text-overflow: unset;
    overflow: unset;
  }
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  padding: 2px;
  min-width: min-content;
  box-sizing: border-box;

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${props => props.bgColor};
    opacity: 0.75;
    z-index: 1;
  }
`;

const P = styled.p`
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 2;
  color: #fff;
  font-size: 13px;
  line-height: 0.8;
  white-space: nowrap;
`;

function BBoxHeader({ children, zIndex, bgColor }) {
  return (
    <Header zIndex={zIndex} bgColor={bgColor}>
      <Title bgColor={bgColor}>{<P>{children}</P>}</Title>
    </Header>
  )
}

export default BBoxHeader
