import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getSelectedPredictionIndex } from '../../../../../../../store/selectors/predictions';
import { setSelectedPredictionIndex, setWasIteraction } from '../../../../../../../store/slices/predictions';

import Image from '../../../../../../../components/Image';
import { Button, Grid } from '@3divi/baseui';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ImageWrap = styled.div`
  width: 80px;
  height: 85px;
  border: 2px solid #ede35a;
  border-radius: 8px;
  overflow: hidden;
`;

const ButtonWrapControll = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => `
    ${props.side}: -5%;
  `}
`;

function DetectedFace({ thumb, allPersonsCount }) {
  const dispatch = useDispatch();
  const selectedPredictionIndex = useSelector(getSelectedPredictionIndex);

  const handleClickPrev = () => {
    const newIndex = selectedPredictionIndex - 1;
    dispatch(setWasIteraction({ wasIteraction: true }));
    dispatch(setSelectedPredictionIndex({ predictionIndex: newIndex >= 0 ? newIndex : --allPersonsCount }));
  }

  const handleClickNext = () => {
    const newIndex = selectedPredictionIndex + 1;
    dispatch(setWasIteraction({ wasIteraction: true }));
    dispatch(setSelectedPredictionIndex({ predictionIndex: newIndex <= allPersonsCount - 1 ? newIndex : 0 }));
  }

  return (
    <Wrapper>
      {allPersonsCount > 1 && (
        <ButtonWrapControll side="left">
          <Button onClick={handleClickPrev} variant="contained" size="m" borderRadius="8px" color="default" iconLeft="back" />
        </ButtonWrapControll>
      )}
      <Grid container justify="center">
        <ImageWrap>
          <Image src={`data:image/jpeg;base64,${thumb}`} />
        </ImageWrap>
      </Grid>
      {allPersonsCount > 1 && (
        <ButtonWrapControll side="right">
          <Button onClick={handleClickNext} variant="contained" size="m" borderRadius="8px" color="default" iconLeft="next" />
        </ButtonWrapControll>
      )}
    </Wrapper>
  )
}

export default DetectedFace;
