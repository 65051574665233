import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDominantEmotion } from '../../../../../../../../../hooks';
import { getFaceMetaDisplayed } from '../../../../../../../../../store/selectors/face-sdk';
import { getSelectedPredictionIndex } from '../../../../../../../../../store/selectors/predictions';
import { capitalize, floatToPercent } from '../../../../../../../../../utils';

import PesonInfoPanel from '../components/PersonInfoPanel';

function DetectedPersonItem({ index, thumb, confidence, face_meta, onToggleList, onSetActivePosition, isSmallList }) {
  const panelRef = useRef(null);
  const isActiveIndex = useSelector(getSelectedPredictionIndex);
  const faceMetaSelected = useSelector(getFaceMetaDisplayed);
  const dominantEmotion = useDominantEmotion(face_meta.emotions);
  const emotions = useMemo(() => {
    return face_meta.emotions.map(emotion => ({
      dominant: emotion.value === dominantEmotion.value,
      value: capitalize(emotion.value),
      confidence: floatToPercent(emotion.confidence),
    }))
  }, [face_meta.emotions, dominantEmotion]);

  useEffect(() => {
    if (isActiveIndex === index)
      onSetActivePosition(panelRef.current.offsetWidth * index + (8 * index))
  }, [isActiveIndex]);

  return (
    <div ref={panelRef}>
      <PesonInfoPanel
        ref={panelRef}
        isSmall={isSmallList}
        active={isActiveIndex === index}
        onClick={onToggleList}
        thumb={`data:image/jpeg;base64,${thumb}`}
        confidence={floatToPercent(confidence)}
        liveness={faceMetaSelected.liveness && {
          value: capitalize(face_meta.liveness.value),
          confidence: floatToPercent(face_meta.liveness.confidence)
        }}
        mask={faceMetaSelected.mask && {
          value: face_meta.mask.value ? "With a mask" : "Without a mask",
          confidence: floatToPercent(face_meta.mask.confidence)
        }}
        gender={faceMetaSelected['age_gender'] && capitalize(face_meta.gender.value)}
        age={faceMetaSelected['age_gender'] && face_meta.age.value}
        emotions={faceMetaSelected.emotions && emotions}
        emotion={faceMetaSelected.emotions && capitalize(dominantEmotion.value)}
      />
    </div>
  )
}

export default DetectedPersonItem;
