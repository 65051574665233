import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  width: 100%;
  border-radius: ${props => props.borderRadius * 8}px;
  background-color: ${props => props.theme.background[props.color]};
`;

function Paper({ color = "primary", children, borderRadius = 1 }) {
  return (
    <Wrap color={color} borderRadius={borderRadius}>
      {children}
    </Wrap>
  )
}

export default Paper;
