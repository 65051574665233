import { useContext } from "react";
import { getUserAgent } from "../../../utils";
import { MediaQueries } from "../MediaQueriesContext";

const useMediaQueries = () => {
  const queries = useContext(MediaQueries);
  const mobileOperatingSystems = getUserAgent();

  return { ...queries, ...mobileOperatingSystems };
};

export default useMediaQueries;