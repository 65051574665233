import asyncForEach from '../../../utils/async';
import { IMAGE_FORMAT, VideoframeExtractor } from './videoframe-extractor';


const REQUEST_CANCELED_MSG = 'Rejected';
export const PRELOAD_TIME_STEP_SEC = 1.5;
export const SAFE_PLAYBACK_TIMESPAN = 1;
export const FPS = 20;
export const PROGRESS_INTERVAL_MS = 1000 / FPS;
export const PREDICT_PRECISION_TIME = 0.1;


export class VideoframePreloader {
  captureFrame = (canvas, video) => {
    const extractor = new VideoframeExtractor();
    const [frameBlob] = extractor.extractVideoframe({ canvas, video });
    const frameFile = new File([frameBlob], 'videoframe.png', {
      type: 'image/' + IMAGE_FORMAT,
    });
    return frameFile;
  };


  getPredictionsForFrames = async (apiRequests, onFrameReceive, getCancelStatus) => {
    const { result, apiRequestsSorted } = this._initResult(apiRequests);
    await asyncForEach(apiRequestsSorted, async (arg) => {
      await this._tryAwaitForPredictions(arg, result, onFrameReceive, getCancelStatus);
    });
    return result;
  };


  _initResult = (apiRequests) => {
    return {
      apiRequestsSorted: Object.entries(apiRequests).sort((prev, next) => +prev[0] > +next[0]),
      result: {
        anyRejected: false,
        predicts: {},
        lastKey: 0,
      },
    };
  };


  _tryAwaitForPredictions = async (arg, result, onFrameReceive, getCancelStatus) => {
    const [key, { promise, frameData }] = arg;
    result.lastKey = key;

    try {
      if (getCancelStatus()) throw REQUEST_CANCELED_MSG;

      const response = await promise;
      this._checkResponseForError(response);
      this._extractPrediction(
        result, response, key, frameData, onFrameReceive, getCancelStatus
      );
    } catch (error) {
      if (error === REQUEST_CANCELED_MSG) {
        result.anyRejected = true;
        throw REQUEST_CANCELED_MSG;
      }

      result.predicts[key] = {
        prediction: null,
        frameData,
      };
      console.log('Promise error', error);
      // добавить обработку позднее
      // console.log(error);
    }
  };


  _checkResponseForError = (response) => {
    if (!response.error) return;
    else if (!response.payload) throw 'No payload';
    //  || response.meta.requestStatus !== 'rejected') return;
    // throw REQUEST_REJECTED;
  };


  _extractPrediction = (
    result, response, key, frameData, onFrameReceive, getCancelStatus) => {
    result.predicts[key] = {
      frameData,
      prediction: response.payload.predictions[0],
    };
    if (onFrameReceive) onFrameReceive(key, getCancelStatus());
  };
}
