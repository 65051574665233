import { useCreateSegmentationColors, useTypeOfSegmentation } from ".";

const useMakePrediction = (objectMeta, sample) => {
  const segmentationType = useTypeOfSegmentation(objectMeta && objectMeta.objects);
  const colors = useCreateSegmentationColors(objectMeta && objectMeta.objects, segmentationType);
  const prediction = objectMeta && ({ ...objectMeta, segmentationType, colors, ...sample });
  return prediction;
}

export default useMakePrediction;
