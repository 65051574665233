import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPredictions } from '../../../../../../store/selectors/predictions';
import { toggleFaceMeta } from '../../../../../../store/slices/face-meta';
import { getFaceMetaItemDisplayed } from '../../../../../../store/selectors/face-sdk';

import { P, RadioOrCheckbox } from '@3divi/baseui';
import { ListItem } from '../../../../../../components/List';

function SettingsRow({ name, readbleName }) {
  const dispatch = useDispatch();
  // const predictions = useSelector(getPredictions);
  const isDisplayed = useSelector(getFaceMetaItemDisplayed(name));

  const onChangeCheckbox = useCallback((e) => {
    dispatch(toggleFaceMeta({ name: e.target.name }));
  }, [])

  // const checkDisabled = (name, predictions) => {
  //   if (predictions)
  //     return predictions.some(
  //       prediction => prediction.objects.some(
  //         objectMeta => !objectMeta[name] && !(objectMeta.face_meta && objectMeta.face_meta[name])
  //       )
  //     );
  // }

  return (
    <ListItem padding={1.5}>
      <RadioOrCheckbox
        key={name}
        onClick={onChangeCheckbox}
        // disabled={checkDisabled(name, predictions)}
        checked={isDisplayed}
        name={name}
        title={<P color="white">{readbleName}</P>}
        type="checkbox"
      />
    </ListItem>
  )
}

export default SettingsRow
