export class TimeTracker {
  static _toStop = false;
  static _selfStopID = 0;
  static _onAllStop = [];
  static _SELF_STOP_TIMEOUT = 500;

  _lastTimeoutID = 0;


  static stopAll() {
    TimeTracker._toStop = true;
    TimeTracker._selfStopID = setTimeout(
      TimeTracker._finalizeStopAll,
      TimeTracker._SELF_STOP_TIMEOUT
    );
  }


  static _finalizeStopAll() {
    TimeTracker._toStop = false;
    window.clearTimeout(this._selfStopID);
    TimeTracker._onAllStop.forEach(handler => handler());
  }


  static _addStopHandler(handler) {
    TimeTracker._onAllStop.push(handler);
  }


  _tick(args, getBreakStatus, onBreak = null, onTick = null) {
    window.clearTimeout(this._lastTimeoutID);

    if (getBreakStatus() || TimeTracker._toStop) {
      if (onBreak) onBreak();
      return;
    }

    this._lastTimeoutID = setTimeout(
      () => {
        if (onTick) onTick(args);
        this._tick(args, getBreakStatus, onBreak, onTick);
      }, 0
    );
  }
}