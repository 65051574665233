import React from 'react';
import styled from 'styled-components';

const DividerView = styled.hr`
  position: relative;
  border: none;
  margin: 0;
  flex-shrink: 0;
  background-color: ${props => props.theme.border[props.color]};
  ${props => props.zIndex && `z-index: ${props.zIndex};`}

  ${props => props.direction === "X" && `
    height: ${props.size}px;
  `}
  ${props => props.direction === "Y" && `
    width: ${props.size}px;
    height: auto;
  `}
`

function Divider({ direction = "X", size = 1, zIndex, dividerColor = "default" }) {
  return (
    <DividerView direction={direction} size={size} color={dividerColor} zIndex={zIndex} />
  )
}

export default Divider;
