function getUserAgent() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return {
    isWindowsPhone: /windows phone/i.test(userAgent),
    isAndroid: /android/i.test(userAgent),
    isiOS: /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream,
  }
}

export default getUserAgent;