function blendColors(from = [], to = [], p = 0.5) {
  const r = Math.round;
  const b = p < 0;
  p = b ? p * -1 : p;
  const f = from;
  const t = to;
  return [
    r(((t[0] - f[0]) * p) + f[0]),
    r(((t[1] - f[1]) * p) + f[1]),
    r(((t[2] - f[2]) * p) + f[2]),
    f[3] < 0 && t[3] < 0 ? 0 : (
      f[3] > -1 && t[3] > -1
        ? r((((t[3] - f[3]) * p) + f[3]) * 10000) / 10000
        : t[3] < 0 ? f[3] : t[3]
    )
  ]
}

export default blendColors;