export const getMock = (data) => {
  const values = Object.values(data);
  const length = values.length;
  let index = -1;


  return async () => {

    index++;
    if (index == length) index = 0;
    const myFirstPromise = await new Promise((resolve) => {
      setTimeout(async () => {
        resolve(values[index]);
      }, 250);
    });
    return myFirstPromise;
  };


}