import React, { useMemo } from 'react';
import { DETECTION_ROUTE, IDENTIFIACATION_ROUTE } from '../routes'
import { Link, useLocation } from 'react-router-dom';

import { Box, Panel, Tab, Tabs, Typography } from '@3divi/baseui';
import Scrollbars from 'rc-scrollbars';
import { Grid } from '@material-ui/core';

const ROUTES = [DETECTION_ROUTE, IDENTIFIACATION_ROUTE];

function MobileMenu() {
  const location = useLocation();
  const mobileMenuTabsStyles = useMemo(() => ({ width: 'max-content', margin: '0 auto', overflowY: 'hidden' }), []);

  return (
    <Panel>
      <Grid direction="column" container spacing={0} alignItems="center">
        <Box p={1.5}>
          <Typography color="default" align="center" size={18} transform="uppercase">3DiVi Face SDK Demo</Typography>
        </Box>
        <Scrollbars
          autoHeight
          autoHide
        >
          <Box style={mobileMenuTabsStyles} px={1.5}>
            <Tabs spacing={1.5} justify="center" lineSize={2} value={location.pathname}>
              {ROUTES.map(route => (
                <Link key={route.name} to={route.path}>
                  <Tab label={route.displayName} value={route.path} />
                </Link>
              ))}
            </Tabs>
          </Box>
        </Scrollbars>
      </Grid>
    </Panel>
  )
}

export default MobileMenu;
