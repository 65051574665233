import React from 'react';
import { useDroparea } from '../../../../../../../components/Droparea/hooks';

import * as S from './components';
import DropArea from '../../../../../../../components/Droparea';
import Image from '../../../../../../../components/Image';
import { Grid } from '@material-ui/core';

import faceSrc from '../../../../../../../images/face.svg';
import faceSrcHovered from '../../../../../../../images/face_hovered.svg';

function UploadFileArea({ innerContent, children, getRef, ...other }) {
  const { hovered } = useDroparea();
  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <DropArea
        placeholder={
          <S.Content ref={getRef} hover={hovered} {...other}>
            {!innerContent ? (
              <S.ImageWrapper>
                <Image src={hovered ? faceSrcHovered : faceSrc} />
              </S.ImageWrapper>
            ) : innerContent}
          </S.Content>
        }
      >
        {children}
      </DropArea>
    </Grid>
  )
}

export default UploadFileArea;
