import React from 'react';
import styled from 'styled-components';

import Button from '../Button';

const ButtonWrap = styled.div`
  z-index: 11;
  cursor: pointer;
`;

function TakePhotoButton({ onClick }) {
  return (
    <ButtonWrap>
      <Button onClick={onClick} icon="camera" text="Take photo" />
    </ButtonWrap>
  )
}

export default TakePhotoButton;
