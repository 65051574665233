import { useState, useEffect, useReducer, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPredictionsStatus } from "../store/selectors/predictions";
import { getPredictions } from "../store/slices/predictions";


const QueueActionType = {
    PUSH: 'push',
    SHIFT: 'shift',
    CLEAN: 'clean',
}

function queueReducer(state, action) {
    switch (action.type) {
        case QueueActionType.PUSH:
            if (state.value.push(action.file) > state.limit)
                state.value.shift()
            return state;
        case QueueActionType.SHIFT:
            state.value.shift()
            return state;
        case QueueActionType.CLEAN:
            state.value = [];
            return state;
        default:
            throw new Error();
    }
}

function useUploadBuffer(bufferSize) {
    const dispatch = useDispatch();
    const status = useSelector(getPredictionsStatus);
    const [stateQueue, dispatchQueue] = useReducer(queueReducer, { value: [], size: bufferSize });

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {
        if (!status.progress && stateQueue.value.length) {
            let file = stateQueue.value.shift()
            dispatchQueue({ type: QueueActionType.SHIFT });
            dispatch(getPredictions({ files: file }));
        }
    });

    const pushFile = (file) => {
        dispatchQueue({
            type: QueueActionType.PUSH,
            file: file
        })
        forceUpdate();
    }

    const cleanUploadQueue = () => {
        dispatchQueue({ type: QueueActionType.CLEAN });
        forceUpdate();
    }

    return { pushFile, cleanUploadQueue }
}

export default useUploadBuffer;