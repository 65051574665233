import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "@3divi/baseui";

const usePhotoSize = (src) => {
  const [resized, setResized] = useState(false);
  const [dimensions, setDimensions] = useState(null);

  const timer = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', debounce(() => {
      clearTimeout(timer.current);
      setResized(true);
      timer.current = setTimeout(() => {
        setResized(false);
      }, 250);
    }, 250));
  }, []);

  const getImageDimensions = useCallback((node) => {
    if (node) {
      const target = node.target || node;
      setDimensions({ width: target.clientWidth, height: target.clientHeight });
    }
  }, [resized]);


  return [dimensions, getImageDimensions];
}

export default usePhotoSize;