import React from 'react';
import styled from 'styled-components';

import { Battery, Box, Panel } from '@3divi/baseui';

const MenuWrapper = styled(Box)`
  width: 180px;
`;

function Menu({ children }) {
  return (
    <MenuWrapper m={2}>
      <Panel borderRadius={1} color="primary">
        <Battery
          borderRadius="8px"
          direction='Y'
          color='primary'
        >
          {children}
        </Battery>
      </Panel>
    </MenuWrapper>
  )
}

export default Menu;
