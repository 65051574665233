import styled from 'styled-components';



export const LegendWrap = styled.div`
  max-width: 224px;
  background-color: white;
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.24);
  border: 1px solid hsla(0,0%,0%,.06);
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 11;
`;