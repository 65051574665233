import { useMemo } from "react";
import simpleColorGenerator from "../utils/simpleColorGenerator";
import seedrandom from 'seedrandom';

import { TYPES_OF_SEGMENTATION } from "./useTypeOfSegmentation";
import { HSLAToRGBA } from "../utils";

const useGenerateColorsBySegmentationType = (objects, segmentationType) => {
  // Generating an array of colors depending on the semgentation type
  const colors = useMemo(() => {
    if (!objects) return null;
    const hueSeed = .0;
    const randomizeSeed = "tulips";
    seedrandom(randomizeSeed);

    let objectsLength = objects.length;
    if (segmentationType === TYPES_OF_SEGMENTATION.semantic)
      objectsLength = new Set(objects.map(object => object.class)).size;

    const generateColors = simpleColorGenerator({ count: objectsLength, seed: hueSeed });
    return generateColors.getColors().map(color => HSLAToRGBA(color));
  }, [objects?.length]);

  return colors;
}

export default useGenerateColorsBySegmentationType;