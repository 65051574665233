export const IMAGE_FORMAT = 'png';
export const IMAGE_QUALITY = 0.92;
export const DEFAULT_MAX_W = 1280;
export const DEFAULT_MAX_H = 960;


export class VideoframeExtractor {
  extractVideoframe({
    canvas, video, width = undefined, height = undefined }) {
    const canvasSize = this.calcCanvasSize(video, width, height);

    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvasSize.width, canvasSize.height);
    return this.getFrameBlobAndUri(canvas);
  }


  getFrameBlobAndUri(canvas) {
    var dataUri = canvas.toDataURL('image/' + IMAGE_FORMAT, IMAGE_QUALITY);
    var data = dataUri.split(',')[1];
    var mimeType = dataUri.split(';')[0].slice(5)

    var bytes = window.atob(data);
    var buf = new ArrayBuffer(bytes.length);
    var arr = new Uint8Array(buf);

    for (var i = 0; i < bytes.length; i++) arr[i] = bytes.charCodeAt(i);

    const blob = new Blob([arr], { type: mimeType });
    return [blob, dataUri];
  }


  calcCanvasSize(video, userWidth, userHeight) {
    const { maxWidth, maxHeight } = this._setMaxCanvasSize(
      userWidth, userHeight
    );

    const videoScale = Math.max(1,
      video.videoWidth / maxWidth,
      video.videoHeight / maxHeight
    );

    return {
      width: video.videoWidth,
      height: video.videoHeight,
    }
  }


  _setMaxCanvasSize(userWidth, userHeight) {
    return {
      maxWidth: userWidth || DEFAULT_MAX_W,
      maxHeight: userHeight || DEFAULT_MAX_H
    }
  };
}