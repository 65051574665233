import React from 'react';
import { useSelector } from 'react-redux';

import GridContainer, { GridItem } from '../../../../../../../../components/Grid';
import { getFaceMetaItemDisplayed } from '../../../../../../../../store/selectors/face-sdk';
import { capitalize } from '../../../../../../../../utils';
import FaceAttributeRow from './FaceAttributeRow';
import FaceAttributeSection from './FaceAttributeSection';

function AnglesAttributes({ angles }) {
  const displayAngles = useSelector(getFaceMetaItemDisplayed('angles'));

  if (!angles || !displayAngles) return null;

  return (
    <GridItem>
      <FaceAttributeSection>
        <GridContainer direction="column" spacing={1.25}>
          {angles && Object.entries(angles).map(([key, value]) => (
            <GridItem key={key}>
              <FaceAttributeRow name={capitalize(key)} value={`${value.toFixed(2)}°`} />
            </GridItem>
          ))}
        </GridContainer>
      </FaceAttributeSection>
    </GridItem>
  )
}

export default AnglesAttributes;
