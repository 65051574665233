import React from 'react';
import { useSelector } from 'react-redux';

import ReportIssue from '../../components/ReportIssue';
import { Box, FACEMACHINE_THEME } from '@3divi/baseui';
import Divider from '../../components/Divider';
import Logo from '../../components/Logo';
import { getImages, getPredictionsStatus } from '../../store/selectors/predictions';
import Menu from '../../components/Menu';



export const MenuContents = () => {
  const { failure, success } = useSelector(getPredictionsStatus);
  const images = useSelector(getImages);
  return (
    <Menu>
      <Logo />
      {(failure || success) ?
        <Box px={0.75} pb={0.75}>
          <Divider dividerColor={FACEMACHINE_THEME.border.contrast} />
          <ReportIssue src={Object.values(images)[0]} />
        </Box> : []}
    </Menu>
  );
};