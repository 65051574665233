import React from 'react';
import { useSelector } from 'react-redux';
import { useMakePrediction } from '../../hooks';
import { getPredictions } from '../../store/selectors/predictions';

import { TYPES_OF_SEGMENTATION } from '../../hooks/useTypeOfSegmentation';
import Legend from '../../components/Legend';
import Sidebar from '../../layers/Sidebar';

function SidebarWrap({ image }) {
  const predictions = useSelector(getPredictions);
  const prediction = useMakePrediction(predictions[0], image);

  return (
    <Sidebar position='center'>
      {prediction && prediction.segmentationType === TYPES_OF_SEGMENTATION.semantic && (
        <Legend objectsColors={prediction.colors.objectsColors} />
      )}
    </Sidebar>
  )
}

export default SidebarWrap;
