import React from 'react';
import styled from 'styled-components';

import { Battery, Button, P } from '@3divi/baseui';
import Divider from '../../../../../components/Divider';
import { breakpoints } from '../../../../../components/MediaQueries/consts';

const NotificationWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    width: 100%;
    padding: 8px;
  }
`;

const TextWrapper = styled.div`
  padding: 4px 8px;
  background-color: ${props => props.theme.background.primary};
  border-radius: ${props => props.borderRadius};
  white-space: nowrap;

  @media only screen and (max-width: ${breakpoints.mobile}px) {
    width: 100%;
    white-space: normal;
  }
`;

function Notification({ text, onClose }) {
  return (
    <NotificationWrapper>
      <Battery>
        <TextWrapper>
          <P color="default">{text}</P>
        </TextWrapper>
        <Divider direction="Y" dividerColor="transparent" size={2} />
        <Button onClick={onClose} color="default" size="xs" variant="contained" iconLeft="close" />
      </Battery>
    </NotificationWrapper>
  )
}

export default Notification;
