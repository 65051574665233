import React from 'react';
import styled from 'styled-components';

import { BulletedList } from '@3divi/baseui';

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${props => props.gutters && `
    padding: ${props.gutters * 8}px 0;
  `}
`;

function List({ children, indent = 1, gutters }) {
  return (
    <Wrap gutters={gutters}>
      <BulletedList indent={indent}>
        {children}
      </BulletedList>
    </Wrap>
  )
}

export default List;
