import React from 'react';

import List from '../../../../../../components/List';
import { useMediaQueries } from '../../../../../../components/MediaQueries/hooks';
import SettingsRow from './SettingsRow';

const LIST_FACE_META = [
  { readbleName: "Bounding box", name: "bbox" },
  { readbleName: "Mask", name: "mask" },
  { readbleName: "Age and Gender", name: "age_gender" },
  { readbleName: "Emotions", name: "emotions" },
  { readbleName: "Liveness", name: "liveness" },
  { readbleName: "Anthropometric points", name: "keypoints" },
  { readbleName: "Angle of rotation", name: "angles" },
]

function Settings() {
  const { isDesktop } = useMediaQueries();
  return (
    <List gutters={1.5} indent={isDesktop ? 0.5 : 1}>
      {LIST_FACE_META.map((item) => (
        <SettingsRow key={item.name} {...item} />
      ))}
    </List>
  )
}

export default Settings;
