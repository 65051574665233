import React from 'react';
import { usePhotoSize } from '../../../../../../hooks';

import FilesUploadControlPanel from '../files-upload-control-panel/FilesUploadControlPanel';
import FilesUploadControlButtons from '../files-upload-control-panel/FilesUploadControlButtons';
import Photo from '../../../../../../components/Photo';
import DetectionPhotoNotification from '../DetectionPhotoNotification';
import DetectionFaces from '../DetectionFaces';
import OverlappingPreloader from '../../../../../../components/OverlappingPreloader';

function DesktopDetectionContent({ image, prediction, maxSize, loading, faces }) {
  const [imageSize, getImageSize] = usePhotoSize(image?.src);

  return (
    <FilesUploadControlPanel
      borderRadius="5vw"
      buttons={<FilesUploadControlButtons />}
    >
      {image && (
        <Photo
          {...image}
          {...(prediction ? prediction : {})}
          getRef={getImageSize}
          maxSize={maxSize}
        >
          {prediction && faces.length > 1 && <DetectionPhotoNotification />}
          {loading && <OverlappingPreloader />}
          {faces && imageSize && <DetectionFaces imageSize={imageSize} faces={faces} />}
        </Photo>
      )}
    </FilesUploadControlPanel>
  )
}

export default DesktopDetectionContent;


