import React, { useEffect, useState } from 'react';
import { bboxToObject } from './GenericObject';

import { BBox } from '../Elements';

const MAX_DISPLAY_COUNT = 2;

function Face({ face, faces, showMessage, boxColor, bboxDisplay, width, height, active, onClick, children }) {
  const bbox = bboxToObject(face.bbox, width, height);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showMessage);
  }, [showMessage]);

  return (
    <BBox
      {...bbox}
      active={active}
      display={bboxDisplay}
      borderWidth={2}
      onClick={onClick}
      borderColor={boxColor}
      onMouseOver={faces?.length < MAX_DISPLAY_COUNT ? null : () => setShow(true)}
      onMouseOut={faces?.length < MAX_DISPLAY_COUNT ? null : () => setShow(false)}
    >
      {children && (faces.length < MAX_DISPLAY_COUNT || show) &&
        children
      }
    </BBox>
  );
}

export default Face;
