import React, { useState } from 'react';
import Icon from '../Img/Icon';
import { Collapse, CollapseIcon } from './Collapser';
import { LegendWrap } from './LegendWrap';
import { Space, Text } from './LegendItem';
import { LegendList } from './LegendList';

function Legend({ objectsColors }) {
  const [isFull, setFull] = useState(true);
  const handlerToggleFull = () => setFull(!isFull);

  return (
    <LegendWrap>
      <Collapse onClick={handlerToggleFull}>
        <CollapseIcon isFull={isFull}>
          <Icon width={24} height={24} icon="collapse" color="#000" viewBox="0 0 24 24" />
        </CollapseIcon>

        {isFull && <Space padding="0 8px"><Text lineHeight={25} fontSize={15}>Collapse</Text></Space>}
      </Collapse>
      <LegendList objectsColors={objectsColors} isFull={isFull} />
    </LegendWrap>
  )
}

export default Legend;
