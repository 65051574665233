import React, { useContext, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQueries } from '../MediaQueries/hooks';
import { DropareaContext } from './DropareaContext';

const DropAreaWrap = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  color: transparent;

  & > label {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    outline: none;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
  }

  & > input {
    opacity: 0;
    width: 0;
    position: absolute;
    z-index: 1;
  }
`;

function DropArea({
  children,
  placeholder
}) {
  const { onHovered, onUploadFile, areaRef, name, loading, disabled } = useContext(DropareaContext);
  const { isDesktop } = useMediaQueries();
  const inputRef = useRef(null);

  useEffect(
    () => { isDesktop && inputRef.current && inputRef.current.focus(); },
    [inputRef.current, isDesktop]
  )
  const [onDragOver, setDragOver] = useState(false);

  const highlight = (e) => {
    e.preventDefault();
    onHovered(true);
  }

  const unhighlight = (e) => {
    e.preventDefault();
    onHovered(false);
  }

  const handlerDrop = (e) => {
    e.preventDefault();
    let dt = e.dataTransfer;
    let files = dt.files;
    onUploadFile(files, name);
  }

  const initDrop = (e) => {
    e.preventDefault();
    unhighlight(e);
    handlerDrop(e);
    setDragOver(false);
  }

  const handlerPasteImage = (e) => {
    e.preventDefault();
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for (let index in items) {
      const item = items[index];
      if (item.kind !== 'file') continue;

      const blob = item.getAsFile();
      onUploadFile([blob], name);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
    }
  }

  const onChangeInput = (e) => {
    onUploadFile(e.target.files, e.target.name);
  }

  const onToggleDragOver = (e) => {
    e.preventDefault();
    if (!disabled && !loading) {
      highlight(e);
      setDragOver(true);
    }
  }

  const onToggleDragLeave = (e) => {
    e.preventDefault();
    if (!disabled && !loading) {
      unhighlight(e);
      setDragOver(false);
    }
  }

  return (
    <DropAreaWrap
      onMouseEnter={highlight}
      onMouseLeave={unhighlight}
      onDragEnter={highlight}
      onDragOver={onToggleDragOver}
      onDragLeave={onToggleDragLeave}
      onDrop={initDrop}
      disabled={disabled}
      onPaste={handlerPasteImage}
    >
      <input
        ref={areaRef}
        id={name}
        name={name}
        type="file"
        accept="video/*, image/*"
        disabled={(disabled || loading) ? "disabled" : ""}
        onChange={onChangeInput}
      />
      <label
        contentEditable={isDesktop}
        ref={inputRef}
        // autoFocus={true}
        htmlFor={name}
      ></label>

      {!children && placeholder}
      {children}
    </DropAreaWrap>
  );
}

export default DropArea;
