import { useMemo } from "react";

const useDominantEmotion = (emotions) => {
  const dominantEmotion = useMemo(() => {
    if (!emotions) return null;

    const maxConfidence = Math.max(...emotions.map(emotion => emotion.confidence));

    return emotions.find((el) => el.confidence === maxConfidence);
  }, [emotions]);

  return dominantEmotion;
}

export default useDominantEmotion;