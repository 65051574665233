import styled from 'styled-components';

export const GridLayout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  ${props => `
    grid-template-columns: ${props.columns || 'auto'};
    grid-template-rows: ${props.rows || 'auto'};
    align-items: ${props.alignItems || 'flex-start'};
    gap: ${props.gap * props.theme.spacing || 0}px;
  `}
`;