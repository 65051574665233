import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPredictionIndex } from '../../../../../store/slices/predictions';
import { getWasIteraction } from '../../../../../store/selectors/predictions';

import Keypoints from './Keypoints';
import DetectionBBoxes from './DetectionBBoxes';

function DetectionFaces({ faces, imageSize }) {
  const dispatch = useDispatch();
  const wasIteraction = useSelector(getWasIteraction);
  const timer = useRef(null);

  useEffect(() => {
    if (faces.length > 1) {
      let currentIndex = 0;
      timer.current = setInterval(() => {
        currentIndex = currentIndex + 1 <= faces.length - 1 ? ++currentIndex : 0;
        dispatch(setSelectedPredictionIndex({ predictionIndex: currentIndex }))
      }, 5000);
    }

    return () => clearInterval(timer.current);
  }, []);

  useEffect(() => {
    if (wasIteraction) clearInterval(timer.current);
  }, [wasIteraction]);

  return (
    faces.map((el, i) => (
      <Fragment key={`object-${el.id}`}>
        <DetectionBBoxes face={el} index={i} imageSize={imageSize} />
        <Keypoints keypoints={el.keypoints} {...imageSize} />
      </Fragment>
    ))
  )
}

export default DetectionFaces;
