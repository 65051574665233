import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getContinuousPlaybackLimit, getPlaybackTime, getPlayRequestedStatus, getVideoDuration, } from '../../store/selectors/tracking-video';
import { actions } from '../../store/slices/tracking-video';
import { PlayerControlPanel } from '@3divi/baseui';
import useFramesPreload from './VideoPrecapture/useFramesPreload';



const TogglePlayButton = styled.button`
  display: inline-block;
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.width || 'auto'};
  padding: 10px 5px;
  margin: 2px 1px;
`;


function VideoControlPanel({ onClick, trackerRef, ...rest }) {
  const dispatch = useDispatch();
  useFramesPreload(trackerRef.current);

  const isPlayRequested = useSelector(getPlayRequestedStatus);
  const duration = useSelector(getVideoDuration);

  const playbackTime = {
    current: useSelector(getPlaybackTime).currentTime,
    limit: useSelector(getContinuousPlaybackLimit)
  };


  const onSeekBegin = useCallback(
    () => dispatch(actions.setSeekingStatus({
      isSeeking: true,
    })),
    [duration]
  );


  const onSeekChange = useCallback(
    (e) => dispatch(actions.setSoughtPlayback({
      soughtPlayback: +e.target.value,
    })),
    [duration]
  );


  const onSeekEnd = useCallback(
    () => dispatch(actions.setSeekingStatus({
      isSeeking: false,
    })),
    [duration]
  );


  return (
    <PlayerControlPanel
      playbackTime={playbackTime}
      isPlayRequested={isPlayRequested}
      max={duration}
      onChangeBegin={onSeekBegin}
      onChange={onSeekChange}
      onChangeEnd={onSeekEnd}
      onClick={onClick}
      {...rest}
    />
  );
}

export default VideoControlPanel;
