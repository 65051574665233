import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0,-100%,0);
  }

  to {
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 72%;
  max-width: 560px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%,calc(-50% + 132px));
  text-align: center;
  font-family: 'Montserrat', 'IBM Plex Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

const Title = styled.div`
  padding: 4px 0;
  animation: ${fadeInDown} .4s ease-in;
`;

const Subtitle = styled.div`
  padding: 4px 0;
  animation: ${fadeIn} 1.2s ease-in;
`;

const H = styled.h1`
  display: inline;
  color: white;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  background-color: ${props => props.theme.background.secondary};
  box-shadow: -8px 0 0 1px ${props => props.theme.background.secondary}, 8px 0 0 1px ${props => props.theme.background.secondary};
`;

const P = styled.p`
  display: inline;
  color: hsla(216,8%,48%,1);
  font-size: 15px;
  line-height: 20px;
  background-color: ${props => props.theme.background.secondary};
  box-shadow: -8px 0 0 1px ${props => props.theme.background.secondary}, 8px 0 0 1px ${props => props.theme.background.secondary};
`;

function Text({ loading }) {
  return (
    <Container>
      <Title>
        <H>{loading ? "Loading..." : "Select photo or video, drag here or copy and paste"}</H>
      </Title>
      {!loading && (
        <Subtitle>
          <P>JPG and PNG no more than 10 Mb</P>
        </Subtitle>
      )}
    </Container>
  );
}

export default Text;
