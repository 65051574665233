import styled from 'styled-components';
import { breakpoints } from '../../../../../../../components/MediaQueries/consts';

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 30vw;
  background-color: ${props => props.theme.background.secondary};
  border: 0.125vw solid transparent;
  border-color: ${props => props.theme.button.default};
  border-radius: ${props => props.borderRadius};
  overflow: hidden;

  ${props => props.hover && `
    border-color: ${props.theme.background.brand};
    z-index: 1;
  `}

  @media only screen and (max-width: ${breakpoints.table}px) {
    width: 40vh;
    height: 40vh;
  }
`;

export const ImageWrapper = styled.div`
  width: 16vw;
  height: 16vw;

  @media only screen and (max-width: ${breakpoints.table}px) {
    width: 20vh;
    height: 20vh;
  }
`;