import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import Webcam from 'react-webcam';
import { StoreContext } from '../../context/StoreContext';
import { Battery, Button } from '@3divi/baseui';

const Stream = styled.div`
  position: relative;
  background: white;
  margin: -20px -40px;

  & video {
    width: 100%;
    height: auto;
  }

  & img {
    width: 100%;
    height: auto;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  width: 100%;
`;

const AnotherButton = styled.button`
  flex: 1 1 0;
  border: none;
  font-size: 17px;
  line-height: 24px;
  padding: 8px 0;
  color: white;
  cursor: pointer;
  background: hsla(212,100%,56%,1);
`;

const SendButton = styled.button`
  flex: 1 1 0;
  border: none;
  font-size: 17px;
  line-height: 24px;
  padding: 8px 0;
  color: white;
  cursor: pointer;
  background: hsla(120,40%,56%,1);
`;

const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user"
};

function PhotoCreator({ onMakePhoto, webcamRef, changeWebcamStatus }) {
  const [preview, setPreview] = useState(null)
  const defaultWebcamRef = React.useRef(null);
  const { setError } = useContext(StoreContext);

  if (webcamRef === undefined) {
    webcamRef = defaultWebcamRef;
  }

  const sendImage = () => {  //function remove alphachannel from image
    var canvas = document.createElement("canvas")
    var context = canvas.getContext('2d');
    var img = new Image();
    img.src = preview
    canvas.width = img.width;
    canvas.height = img.height;
    context.drawImage(img, 0, 0);
    canvas.toBlob((blob) => {
      var file = new File([blob], 'jpegImage.jpg', { type: "image/jpeg" })
      onMakePhoto([file]);
      changeWebcamStatus();
    }, "image/jpeg", 0.95);

  }

  const makeScreenshot = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setPreview(imageSrc);
    },
    [webcamRef]
  );

  const another = () => {
    setPreview(null);
  }

  const onUserMediaError = e => {
    setError("Please allow access to your camera to continue");
  }

  const onUserMedia = e => {
    setError("");
  }

  return (
    <Stream>
      {preview && (
        <Battery direction="column" borderRadius={0}>
          <img src={preview} width="320" height="240" />
          <Buttons>
            <AnotherButton onClick={another}>Capture another</AnotherButton>
            <SendButton onClick={sendImage}>Send photo</SendButton>
          </Buttons>
        </Battery>
      )}
      {!preview && (
        <Battery borderRadius="0" direction="column">
          <Webcam
            audio={false}
            height={240}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={320}
            videoConstraints={videoConstraints}
            onUserMediaError={onUserMediaError}
            onUserMedia={onUserMedia}
          />
          <Button size="m" variant="contained" color="primary" elastic onClick={makeScreenshot} text="Capture photo" />
        </Battery>
      )}
    </Stream>
  )
}

export default PhotoCreator;
