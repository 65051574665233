function getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
}

function getFileType(file) {
    var parts = file.type.split('/');
    return parts[0];
}

function isImageFilename(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'jpg':
        case 'gif':
        case 'bmp':
        case 'png':
            //etc
            return true;
    }
    return false;
}

function isVideoFilename(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'm4v':
        case 'avi':
        case 'mpg':
        case 'mp4':
            // etc
            return true;
    }
    return false;
}

function isVideoFile(file) {
    return getFileType(file) == 'video'
}

function isImageFile(file) {
    return getFileType(file) == 'image'
}

// todo
export { isImageFile, isVideoFile }