import Comparison from "./pages/Comparison";
import ComparisonAll from "./pages/ComparisonAll";
import Detection from "./pages/detection";

export const DETECTION_ROUTE = {
  path: '/detection',
  component: Detection,
  name: 'detection',
  displayName: 'Detection'
};

export const VERIFICATION_ROUTE = {
  path: '/verification',
  component: Comparison,
  name: '1:1_verification',
  displayName: '1:1 Verification'
};

export const IDENTIFIACATION_ROUTE = {
  path: '/identification',
  component: ComparisonAll,
  name: '1:N_identification',
  displayName: '1:N Identification'
};

export const ROUTES = [
  DETECTION_ROUTE,
  VERIFICATION_ROUTE,
  IDENTIFIACATION_ROUTE
]
