import React from 'react';

import GridContainer, { GridItem } from '../../../../../../../../components/Grid';
import { Box, Divider } from '@3divi/baseui';

function FaceAttributeSection({ children, divider }) {
  return (
    <GridContainer direction="column" spacing={1.5}>
      <GridItem>
        <Box px={1.5}>
          {children}
        </Box>
      </GridItem>
      {divider && (
        <GridItem>
          <Divider />
        </GridItem>
      )}
    </GridContainer>
  )
}

export default FaceAttributeSection;
