import { TimeTracker } from "./time-tracker";
import { VideoUtils } from "./video-utils";


export class VideoframeRenderer extends TimeTracker {
  _isPlaying = false;
  _lastRenderID = 0;


  constructor(canvas, videoSrc, onVideoLoaded) {
    super();

    const video = new VideoUtils().getOrCreateElement('video', 'main-video');
    video.onloadedmetadata = (e) => {
      if (onVideoLoaded) onVideoLoaded(e.target);
    };
    video.oncanplay = () => this._renderVideoframe();

    video.src = videoSrc;
    video.hidden = true;

    this._video = video;
    this._context = canvas.getContext('2d');
    this._w = canvas.width;
    this._h = canvas.height;
  }


  togglePlay(onProgress, on = null) {
    if (this._isPlaying) this._stopVideo();
    else this._playVideo(onProgress);
  }


  seekTo(timePosition) {
    this._video.currentTime = timePosition;
    this._renderVideoframe();
  }


  _playVideo(onProgress) {
    this._video.play();
    this._isPlaying = true;
    this._tick(
      onProgress,
      () => !this._isPlaying,
      null,
      (e) => this._trackProgress(e)
    )
  }


  _stopVideo() {
    this._video.pause();
    window.clearTimeout(this._lastRenderID);
    this._isPlaying = false;
  }


  _renderVideoframe() {
    const { _context } = this;
    _context.drawImage(this._video, 0, 0, this._w, this._h);
    // console.log('rendering', this._video.videoWidth, this._video.videoHeight, this._video.currentTime);
  }


  _trackProgress(onProgress) {
    onProgress(this._video.currentTime);
    this._renderVideoframe();
  }
}