import React, { useContext } from "react";
import styled from "styled-components";
import { indent } from "./consts";
import { GridContext } from "./GridContext";

const GridItem = styled.div`
  width: 100%;
  margin: 0;
  box-sizing: border-box;

  padding: ${props => props.offset * 0.5}px;
`

const Item = ({ children }) => {
  const { spacing = 2 } = useContext(GridContext);

  return (
    <GridItem offset={indent * spacing}>
      {children}
    </GridItem>
  );
};

export default Item;
