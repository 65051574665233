import React from 'react';
import { useMediaQueries } from '../../../components/MediaQueries/hooks';

import DesktopMenu from './DekstopMenu';
import MobileMenu from './MobileMenu';
import Menu from '../../../layers/Menu';

function MenuFaceSdk() {
  const { isDesktop } = useMediaQueries();
  return (
    <Menu>
      {isDesktop && <DesktopMenu />}
      {!isDesktop && <MobileMenu />}
    </Menu>
  )
}

export default MenuFaceSdk;
