import React from 'react';
import styled from 'styled-components';

import Icon from './Icon';

const StyledImg = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  border: 4px solid white;
  transform: translate3d(-50%,-50%,0);
  width: 120px;
  height: 120px;
  border-radius: 20px;
  z-index: 1;
`;

function Img(props) {
  const {
    onArea,
    loading
  } = props;
  return (
    <StyledImg>
      <Icon width="80" height="80" padding="16" icon={(onArea || loading) ? "download" : "people"} />
    </StyledImg>
  );
}

export default Img;
