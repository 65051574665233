import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMatches, getPredictions } from '../../../../../store/selectors/predictions';

import GridContainer, { GridItem } from '../../../../../components/Grid';
import SameMatches from './SameMatches';
import DifferentMatches from './DifferentMatches';

function ComparisonSidebar() {
  const predictions = useSelector(getPredictions);
  const matches = useSelector(getMatches);
  const matchesWithImages = useMemo(() => {
    if (!matches) return null;
    return matches.map(matchData => ({
      ...matchData,
      images: getImagesByMatches(matchData.objects, predictions)
    }))
  }, [matches]);

  const same = useMemo(() => matchesWithImages?.filter(match => match.is_similar), [matchesWithImages]);
  const differents = useMemo(() => matchesWithImages?.filter(match => !match.is_similar), [matchesWithImages]);

  if (predictions.length <= 0 || !matchesWithImages) return null;

  return (
    <GridContainer spacing={3} direction="column">
      {same.length > 0 && (
        <GridItem>
          <SameMatches sameMatches={same} />
        </GridItem>
      )}
      {differents.length > 0 && (
        <GridItem>
          <DifferentMatches differentMatches={differents} />
        </GridItem>
      )}
    </GridContainer>
  )
}

function getImagesByMatches(matchingObjects, predictions) {
  const images = [];
  matchingObjects.forEach(objectPath => {
    const objectPathSplitted = objectPath.split('@');
    const index = parseInt(objectPathSplitted[0]) - 1;
    const id = +objectPathSplitted[1];
    const predictionObject = predictions[index].objects.find(object => object.id === id);
    images.push(predictionObject.thumb);
  });
  return images;
}

export default ComparisonSidebar;
