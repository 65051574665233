import React, { useMemo } from 'react';
import { useCheckMediaQuery } from './hooks';
import { MediaQueriesProvider } from './MediaQueriesContext';

function MediaQueries({ breakpoints, children }) {
  const isDesktop = useCheckMediaQuery({ minWidth: breakpoints.table });
  const isMobile = useCheckMediaQuery({ maxWidth: breakpoints.mobile });
  const isTable = useCheckMediaQuery({ minWidth: breakpoints.mobile, maxWidth: breakpoints.table });

  const values = useMemo(() => ({ isMobile, isTable, isDesktop }), [isMobile, isTable, isDesktop]);

  return (
    <MediaQueriesProvider value={values}>
      {children}
    </MediaQueriesProvider>
  )
}

export default MediaQueries;
