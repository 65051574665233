import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearPredictions } from '../../../../store/slices/predictions';

import { P } from '@3divi/baseui';
import Footer from '../../../../layers/Footer';
import Content from '../../../../layers/Content';
import ComparisonContent from './ComparisonContent';
import Sidebar from '../../../../layers/Sidebar';
import ComparisonSidebar from './Sidebar/ComparisonSidebar';

function Comparison() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearPredictions({}));
  }, []);

  return (
    <>
      <Content>
        <ComparisonContent />
      </Content>
      <Sidebar position="center" spacing={3}>
        <ComparisonSidebar />
      </Sidebar>
      <Footer>
        <P color="secondary">Drag-and-drop two photos here to compare faces</P>
      </Footer>
    </>
  )
}

export default Comparison
