import React from 'react';
import { useSelector } from 'react-redux';
import { getFaceMetaDisplayed } from '../../../../../../../../store/selectors/face-sdk';
import { capitalize, floatToPercent } from '../../../../../../../../utils';

import GridContainer, { GridItem } from '../../../../../../../../components/Grid';
import FaceAttributeRow from './FaceAttributeRow';
import FaceAttributeSection from './FaceAttributeSection';

const DEFAULT_FIELDS = ['gender', 'age'];

function FaceAttributes({ faceMeta }) {
  const faceMetaSelected = useSelector(getFaceMetaDisplayed);

  if (!faceMeta || [...DEFAULT_FIELDS, 'liveness', 'mask'].every(el => !faceMetaSelected[el])) return null;

  return (
    <GridItem>
      <FaceAttributeSection divider>
        <GridContainer direction="column" spacing={1.25}>
          {faceMetaSelected['age_gender'] && (
            <GridItem>
              <FaceAttributeRow name={`${capitalize(faceMeta.gender.value)}, ${capitalize(faceMeta.age.value)} years`} />
            </GridItem>
          )}
          {faceMetaSelected.liveness && (
            <GridItem>
              <FaceAttributeRow name={`Face is ${capitalize(faceMeta.liveness.value)}`} value={`${floatToPercent(faceMeta.liveness.confidence)}%`} />
            </GridItem>
          )}
          {faceMetaSelected.mask && (
            <GridItem>
              <FaceAttributeRow name={faceMeta.mask.value ? "With a mask" : "Without a mask"} value={`${floatToPercent(faceMeta.mask.confidence)}%`} />
            </GridItem>
          )}
        </GridContainer>
      </FaceAttributeSection>
    </GridItem >
  )
}

export default FaceAttributes;
