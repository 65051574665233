import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFaceMetaItemDisplayed } from '../../../../../store/selectors/face-sdk';
import { getSelectedPredictionIndex } from '../../../../../store/selectors/predictions';
import { setSelectedPredictionIndex, setWasIteraction } from '../../../../../store/slices/predictions';

import Face from '../../../../../components/Objects/Face';
import { FACEMACHINE_THEME } from '@3divi/baseui';

function DetectionBBoxes({ face, index, imageSize }) {
  const dispatch = useDispatch();
  const bboxDisplay = useSelector(getFaceMetaItemDisplayed('bbox'));
  const selectedPredictionIndex = useSelector(getSelectedPredictionIndex);

  const handleClickBbox = useCallback((e) => {
    dispatch(setWasIteraction({ wasIteraction: true }));
    dispatch(setSelectedPredictionIndex({ predictionIndex: index }));
  }, [])

  return (
    <Face
      face={face}
      bboxDisplay={bboxDisplay}
      active={selectedPredictionIndex === index}
      boxColor={selectedPredictionIndex === index ? '#ede35a' : FACEMACHINE_THEME.button.error}
      onClick={handleClickBbox}
      {...imageSize}
    />
  )
}

export default DetectionBBoxes;
