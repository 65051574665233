

import { Icon } from '@3divi/baseui';
import React from 'react'
import styled from 'styled-components'

const MarkWrapper = styled.div`
  width: 65%;
  height: 65%;
  margin: auto;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;


// цвет, соответствующий шаблону не найден, необходимо добавить и заменить константу
const Circle = styled.div`
    display: flex;
    justify-content: center;
    border-radius: 50%;
    color: white;
    background: radial-gradient(${props => props.theme.background.brand} 60%, blue);
    width: ${props => props.size}px;
    height: ${props => props.size}px;    
`;

function MarkIcon({ size }) {

  return (
    <Circle size={size}>
      <MarkWrapper>
        <Icon icon='mark' color='contrast' />
      </MarkWrapper>
    </Circle>
  )
}

export default MarkIcon
