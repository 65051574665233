import React from 'react';

import { Grid } from '@material-ui/core';
import DetectedPersonItem from './DetectedPersonItem';

function DetectedPersonList({ detectedPersons, onToggleList, onSetActivePosition, isSmallList }) {
  return (
    <Grid spacing={1} container direction="row" alignItems="center" wrap="nowrap">
      {detectedPersons.map((person, index) => (
        <Grid key={person.thumb} item>
          <DetectedPersonItem onSetActivePosition={onSetActivePosition} index={index} onToggleList={onToggleList} isSmallList={isSmallList} {...person} />
        </Grid>
      ))}
    </Grid>
  )
}

export default DetectedPersonList;
