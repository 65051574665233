import React from 'react';
import { useSelector } from "react-redux";
import Background from "../../components/Background";
import { getPredictionsStatus } from "../../store/selectors/predictions";


const BackgroundWrapper = ({ size, space, borderRadius, isImg }) => {
  const { progress, success } = useSelector(getPredictionsStatus);
  return (
    <Background
      size={size}
      space={space}
      borderRadius={borderRadius}
      isLoading={progress}
      isLoaded={success}
      isImg={isImg}
    />
  )
}


export default BackgroundWrapper;