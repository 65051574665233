import React from 'react';
import styled from 'styled-components';

import { Box, Typography } from '@3divi/baseui';
import Paper from '../Paper/Paper';

const Wrap = styled.div`
  width: 100%;
`;

function SidebarSection({ title, header, children }) {
  return (
    <Wrap>
      <Box px={1} pb={1}>
        {header && header}
        {!header && title && <Typography transform="uppercase" spacing={1} weight={500} color="secondary" size={12}>{title}</Typography>}
      </Box>
      <Paper>
        {children}
      </Paper>
    </Wrap>
  )
}

export default SidebarSection;
