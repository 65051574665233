import React from 'react';
import { useSelector } from 'react-redux';
import { getFaceMetaItemDisplayed } from '../../../../../store/selectors/face-sdk';

import { KeyPoint } from '../../../../../components/Elements';

function Keypoints({ keypoints, width, height }) {
  const keypointsDisplayed = useSelector(getFaceMetaItemDisplayed('keypoints'));

  if (!keypointsDisplayed || !keypoints) return null;

  return (
    Object.entries(keypoints).map(([name, keypoint]) => (
      <KeyPoint
        pointColor="red"
        key={`keypoint-${name}`}
        x={keypoint.proj[0] * width}
        y={keypoint.proj[1] * height}
        radius={4}
      />
    ))
  )
}

export default Keypoints;
