import React from 'react';
import { Button } from '@3divi/baseui';

// const ReportIssueButtonStyle = {
//   padding: "10px 8px",
//   "&:hover": {
//     borderColor: "hsla(6, 78%, 57%, .48)",
//   },
// };


function ReportIssueButton({ onClick }) {
  return (
    <Button
      variant='contained'
      elastic
      color="secondary"
      onClick={onClick}
      text="Report Issue"
      size="m"
    />
  )
}

export default ReportIssueButton;
