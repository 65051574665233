
export default function createImage(file) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    let _URL = window.URL;

    if (file && !file.type.match('image')) {
      return reject('Not image');
    }

    img.onload = function () {
      resolve(this);
    };

    img.src = _URL.createObjectURL(file);
  });
}