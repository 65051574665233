import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import mockData from '../../mock/mockDataWithOneSegments.json';

import { getMock } from './mock-factory';
import * as videoMock from './mock-video-data.json';
const mockFactory = getMock(videoMock[1]);


export const getPredictions = createAsyncThunk(
  'predictions/getPredictions',
  async ({ files, requestCanceller }) => {
    try {
      const url = '/api';
      const formData = new FormData();

      files.forEach((file, i) => formData.append(`file${i || ''}`, file));
      formData.append('generic', '');

      const result = await axios.post(url, formData, {
        cancelToken: requestCanceller?.token,
      });


      if (result.status !== 200) throw new Error("Server is not responding");

      const response = result.data;
      if (response.Error) throw new Error(response.Error);

      const predictions = response.samples ? response.samples : [response];

      if (predictions.every(predict => !predict.objects.length)) throw new Error("No objects detected");

      // const predictions = [mockFactory()];
      // const predictions = [mockData];

      return {
        predictions: predictions,
        matches: response.matches,
      };
    }
    catch (error) {
      if (error !== 'Request aborted') console.log(error)
      throw error;
    }
  },
)

const initialState = {
  isFileUploaded: false,
  predictions: [],
  legendSelectedClasses: [],
  images: {},
  selectedPredictionIndex: 0,
  wasIteraction: false,
  matches: null,
  hoveredMatch: {},
  status: {
    progress: false,
    success: false,
    failure: false,
  },
  error: null
}

export const predictionsSlice = createSlice({
  name: "predictions",
  initialState,
  reducers: {

    setFileUploaded: (state) => {
      state.isFileUploaded = true;
    },

    clearPredictions: (state) => {
      return initialState;
    },

    initLegendClasses: (state, action) => {
      state.legendSelectedClasses = action.payload.legendSelectedClasses;
    },

    toggleLegendClass: (state, action) => {
      const { className } = action.payload;
      let { legendSelectedClasses } = state;

      const findClassNameIndex = legendSelectedClasses.indexOf(className);
      legendSelectedClasses = findClassNameIndex >= 0 ?
        legendSelectedClasses.splice(findClassNameIndex, 1) :
        legendSelectedClasses.push(className);
    },

    setImages: (state, actions) => {
      const { imgLoaderName, imgSrc } = actions.payload;
      state.images[imgLoaderName] = imgSrc;
    },

    setSelectedPredictionIndex: (state, actions) => {
      const { predictionIndex } = actions.payload;
      state.selectedPredictionIndex = predictionIndex;
    },

    setWasIteraction: (state, actions) => {
      const { wasIteraction } = actions.payload;
      state.wasIteraction = wasIteraction;
    },

    setHoveredMatch: (state, actions) => {
      state.hoveredMatch = actions.payload.match;
    }
  },
  extraReducers: {
    [getPredictions.pending]: (state) => {
      state.error = null;
      state.predictions = [];
      state.matches = null;
      state.status = {
        progress: true,
        success: false,
        failure: false
      }
    },
    [getPredictions.fulfilled]: (state, action) => {
      const { predictions, matches } = action.payload;
      state.predictions = predictions;
      state.matches = matches;

      state.status = {
        progress: false,
        success: true,
        failure: false
      }
    },
    [getPredictions.rejected]: (state, action) => {
      state.status = {
        progress: false,
        success: false,
        failure: true
      }
      state.error = action.error.message;
    }
  }
})

export const {
  clearPredictions,
  setFileUploaded,
  setImages,
  toggleLegendClass,
  initLegendClasses,
  setSelectedPredictionIndex,
  setWasIteraction,
  setHoveredMatch
} = predictionsSlice.actions;

export default predictionsSlice.reducer;
