import { useEffect, useMemo, useState } from "react";

const useCheckMediaQuery = ({ minWidth = 0, maxWidth = 0 }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const updateWindowSize = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      updateWindowSize();
    });

    updateWindowSize();

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);


  const checked = useMemo(() => {
    if (maxWidth && minWidth)
      return width >= minWidth && width <= maxWidth;

    if (maxWidth)
      return width <= maxWidth;

    return width >= minWidth;
  }, [width]);

  return checked;
}

export default useCheckMediaQuery;