import React from 'react';
import styled from 'styled-components';


const StyledKeyPoint = styled.div`
  position: absolute;
  width: ${props => Math.floor(props.radius * 2)}px;
  height: ${props => Math.floor(props.radius * 2)}px;
  top: ${props => Math.floor(props.top - props.radius)}px;
  left: ${props => Math.floor(props.left - props.radius)}px;
  border: ${props => `2px solid ${props.pointColor}`};
  border-radius: ${props => Math.floor(props.radius)}px}
  box-sizing: border-box;
  pointer-events: none;
`;

function KeyPoint({ x, y, radius, pointColor = 'hsla(0,96%,48%,1)', onMouseOver, onMouseOut, children }) {
  return (
    <StyledKeyPoint
      radius={radius}
      left={x}
      top={y}
      pointColor={pointColor}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children}
    </StyledKeyPoint>
  );
}

export default KeyPoint;
