import React from 'react';
import { useDroparea } from '../../../../../../../components/Droparea/hooks';
import { usePhotoSize, useWebcamDisplay } from '../../../../../../../hooks';
import { useMediaQueries } from '../../../../../../../components/MediaQueries/hooks';

import FilesUploadControlPanel from '../../files-upload-control-panel/FilesUploadControlPanel';
import { Battery, Box, Button, Divider, Panel, Popover } from '@3divi/baseui';
import TakePhoto from '../../../../../../../components/TakePhoto';
import Settings from '../../settings';
import { GridLayout } from '../../../../../../../components/Grid/GridLayout';
import Footer from '../../../../../../../layers/Footer';
import DetectedPersons from './detected-persons';
import { Grid } from '@material-ui/core';
import Photo from '../../../../../../../components/Photo';
import DetectionPhotoNotification from '../../DetectionPhotoNotification';
import DetectionFaces from '../../DetectionFaces';
import OverlappingPreloader from '../../../../../../../components/OverlappingPreloader';


const buttonsProps = {
  variant: "contained",
  color: "default",
  size: "l"
}

function UploadedFileScreen({ image, prediction, maxSize, faces, loading }) {
  const [showWebcam, changeDisplayWebcam] = useWebcamDisplay();
  const { name, onUploadFile, onOpenExplorer } = useDroparea();
  const [imageSize, getImageSize] = usePhotoSize(image?.src);
  const { isTable, isMobile, isAndroid, isiOS } = useMediaQueries();
  const isAndroidOriOS = isAndroid || isiOS;

  return (
    <>
      <TakePhoto onUploadFile={onUploadFile} inputName={name} isOpen={showWebcam} changeWebcamStatus={changeDisplayWebcam} />
      <GridLayout gap={2} alignItems="center" rows="3fr minmax(100px, 1fr)">
        <Grid spacing={3} container direction="column" alignItems="center" justify="flex-start">
          <FilesUploadControlPanel
            borderRadius="5vh"
            buttons={
              isTable && (
                <Box py={1}>
                  <Grid wrap="nowrap" container alignItems="center" justify="space-between">
                    <Grid item>
                      <Battery borderRadius="8px" direction="row">
                        <Button {...buttonsProps} text="Select photo" elastic onClick={onOpenExplorer} iconLeft="upload" />
                        <Divider direction="Y" size={3} color="primary" />
                        <Button {...buttonsProps} text="Take photo" elastic onClick={changeDisplayWebcam} iconLeft="camera" />
                      </Battery>
                    </Grid>
                    <Grid item>
                      <Popover
                        anchor={{ vertical: 'top', horizontal: 'right' }}
                        transform={{ vertical: "bottom", horizontal: "right" }}
                        content={<Panel color="primary" borderRadius={1}><Settings /></Panel>}
                        spacing={1}
                      >
                        <Button {...buttonsProps} width="100px" borderRadius="8px" iconLeft="settings" />
                      </Popover>
                    </Grid>
                  </Grid>
                </Box>
              )
            }
          >
            {image && (
              <Photo
                {...image}
                {...(prediction ? prediction : {})}
                getRef={getImageSize}
                maxSize={maxSize}
              >
                {prediction && faces.length > 1 && <DetectionPhotoNotification />}
                {loading && <OverlappingPreloader />}
                {faces && imageSize && <DetectionFaces imageSize={imageSize} faces={faces} />}
              </Photo>
            )}
          </FilesUploadControlPanel>
          {isMobile && (
            <Grid item wrap="nowrap" container alignItems="center" justify="space-between">
              <Grid item>
                {isAndroidOriOS && (
                  <Button {...buttonsProps} width="72px" onClick={onOpenExplorer} iconLeft="upload" />
                )}
                {!isAndroidOriOS && (
                  <Battery borderRadius="8px" direction="row">
                    <Button {...buttonsProps} width="72px" onClick={onOpenExplorer} iconLeft="upload" />
                    <Divider direction="Y" size={3} color="primary" />
                    <Button {...buttonsProps} width="72px" onClick={changeDisplayWebcam} iconLeft="camera" />
                  </Battery>
                )}
              </Grid>
              <Grid item>
                <Popover
                  anchor={{ vertical: 'top', horizontal: 'right' }}
                  transform={{ vertical: "bottom", horizontal: "right" }}
                  content={<Panel color="primary" borderRadius={1}><Settings /></Panel>}
                  spacing={1}
                >
                  <Button {...buttonsProps} width="72px" borderRadius="8px" iconLeft="settings" />
                </Popover>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Footer><DetectedPersons /></Footer>
      </GridLayout>
    </>
  )
}

export default UploadedFileScreen;
