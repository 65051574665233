import React from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';

import { Modal, Input, Button, Box, DEMO, Panel } from '@3divi/baseui';
import Paper from '../Paper';

const SubmitButtonWrapper = styled.div`
  display: flex;
  align-items: center; 
  width: 100%;
`;

const CloseButton = styled.a`
  display: inline-block;
  font: ${props => props.theme.font.s};
  color: ${props => props.theme.link.disabled};

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.link.hover};
  }

  &:focus {
    color: ${props => props.theme.link.hover};
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.link.hover};
  }
`;

function ReportIssueForm({
  isOpen, onSendReport, onToggleForm, onTextareaTextChange,
  textareaValue, inputErrorText, submitErrorText, tryingToSend }) {

  return (
    <ThemeProvider theme={DEMO}>
      <Modal
        close={onToggleForm}
        fixed={600}
        isOpen={isOpen}
        title='Report Issue'
      >
        {submitErrorText && <Panel
          color={'error'}
          borderRadius={1}
          opacityValue={0.2}
        >
          <Box p={2}>
            {submitErrorText}
          </Box>
        </Panel>}

        <Input
          type='textarea'
          maxlength={1000}
          onChange={onTextareaTextChange}
          value={textareaValue}
          label='Your issue'
          placeholder='Describe your problem...'
          variant='column'
          errorText={inputErrorText}
          lines='9'
        />

        <Box pt={1}>
          <SubmitButtonWrapper>
            <Button
              variant='contained'
              size='m'
              width='170px'
              text='Send'
              color='primary'
              onClick={onSendReport}
              loading={tryingToSend}
            />

            <Box px={2}>
              <CloseButton
                href='#'
                onClick={onToggleForm}
              >
                or Cancel
              </CloseButton>
            </Box>
          </SubmitButtonWrapper>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

export default ReportIssueForm;
