import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Selectors from '../../store/selectors/tracking-video';
import { actions } from '../../store/slices/tracking-video';
import { IMAGE_FORMAT, VideoframeExtractor } from './VideoPrecapture/videoframe-extractor';
import { VideoframeRenderer } from './VideoPrecapture/videoframe-renderer';
import VideoPrediction from './VideoPrediction';


const FrameScreenCanvas = styled.canvas`
  position: absolute;
  left: 0;
  width: 640px;
  height: 360px;  
  opacity: 1;
`;


function FrameScreen({ src, frameSize }) {

  const dispatch = useDispatch();
  const canvasRef = useRef(null);

  const soughtPlayback = useSelector(Selectors.getSoughtPlaybackNormal); // для скрола
  const isPreloaded = useSelector(Selectors.getPreloadedStatus);
  const shouldPlay = useSelector(Selectors.getShouldPlayStatus);

  const toPlay = shouldPlay && isPreloaded;
  const [videoframeRenderer, setVideoframeRenderer] = useState(null);


  useEffect(
    () => {
      console.log('callback on current, src');
      canvasRef.current && setVideoframeRenderer(new VideoframeRenderer(
        canvasRef.current,
        src,
        video => {
          dispatch(actions.setDuration({ duration: video.duration }));
        }
      ));
    },
    [canvasRef.current, src]
  );


  useEffect(
    () => {
      videoframeRenderer && videoframeRenderer.seekTo(soughtPlayback);
    },
    [soughtPlayback]
  );


  useEffect(
    () => {
      videoframeRenderer && videoframeRenderer.togglePlay(currentTime => {
        dispatch(actions.setPlaybackTime({
          currentTime
        }));
      });
    },
    [toPlay]
  )


  return (
    <>
      <FrameScreenCanvas ref={canvasRef} />
      <VideoPrediction frameSize={frameSize} />
    </>
  )
}

export default FrameScreen;