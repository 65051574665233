import { createGlobalStyle } from 'styled-components';
import { breakpoints } from './components/MediaQueries/consts';

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
    background-color: ${props => props.theme.background.secondary};

    @media only screen and (max-width: ${breakpoints.mobile}px) {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;

export default GlobalStyle;