import React, { useCallback, useEffect, useRef, useState } from 'react';

import Notification from '../components/Notification';

function DetectionPhotoNotification() {
  const [showNotification, setShowNotification] = useState(true);
  const showingNotificationTimer = useRef(null);

  useEffect(() => {
    showingNotificationTimer.current = setTimeout(() => {
      setShowNotification(false);
    }, 10000);

    return () => clearTimeout(showingNotificationTimer.current);
  }, []);

  const handleCloseNotification = useCallback(() => {
    setShowNotification(false);
  }, []);

  if (!showNotification) return null;

  return (
    <Notification text="Click on the face to view the detection information" onClose={handleCloseNotification} />
  )
}

export default DetectionPhotoNotification;
