import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLegendSelectedClasses } from '../../store/selectors/predictions';
import { TYPES_OF_SEGMENTATION } from "../../hooks/useTypeOfSegmentation";
import { makeRGBAString } from '../../utils';

import { Face, GenericObject } from '../../components/Objects';
import Mask from '../../components/Mask';
import { useMakePrediction } from '../../hooks';
import Msg from '../../components/Msg/Msg';
import ListFaceMeta from './ListFaceMeta';
import { Box } from '@3divi/baseui';



function Prediction({ prediction, imageSize }) {
  const coloredPrediction = useMakePrediction(prediction);

  const legendSelectedClasses = useSelector(getLegendSelectedClasses);

  const filteredColorsClasses = useMemo(() =>
    filterColorsForClasses(coloredPrediction, legendSelectedClasses),
    [coloredPrediction, legendSelectedClasses]
  );

  if (!coloredPrediction) return null;


  const genericObjects = coloredPrediction &&
    coloredPrediction.objects.filter(el => el.class != "face");
  const faces = coloredPrediction &&
    coloredPrediction.objects.filter(el => el.class == "face");

  return (<>
    {coloredPrediction.segmentationType  &&
      <Mask
        {...imageSize}
        segmentId={coloredPrediction.segment_id}
        segmentColors={coloredPrediction.segmentationType == TYPES_OF_SEGMENTATION.semantic ?
           filteredColorsClasses : coloredPrediction.colors.objectsColors}
      />
    }
    {genericObjects.map((elem) => {
      return (
        <GenericObject
          key={`object-${elem.id}`}
          object={elem}
          boxColor={makeRGBAString(coloredPrediction.colors.objectsColors[elem.id].color)}
          width={imageSize.width}
          height={imageSize.height}
        />
      )
    })}
    {faces && faces.map(elem => (
      <Face
        key={`object-${elem.id}`}
        face={elem}
        boxColor="red"
        width={imageSize.width}
        height={imageSize.height}
        faces={faces}>
        <Msg>
          <Box py={1}>
            <ListFaceMeta face={elem} />
          </Box>
        </Msg>
      </Face>
    ))}
  </>)
}

export default Prediction;



function filterColorsForClasses(data, legendSelectedClasses) {
  if (!data) return {};

  // Фильтр областей выбранных классов для данных "1 класс = 1 сегмент"
  const rawObjectsForSelectedClasses = data.objects
    .filter(dataset => legendSelectedClasses.includes(dataset.class))
    .reduce((result, dataset) => ({
      ...result,
      [dataset.segments[0]]: data.colors.segmentsColors[dataset.segments[0]]
    }), {});
  return rawObjectsForSelectedClasses;
}
