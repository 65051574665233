import React from 'react';
import styled from 'styled-components';


const StyledBBox = styled.div`
  position: absolute;
  width: ${props => Math.floor(props.width)}px;
  height: ${props => Math.floor(props.height)}px;
  top: ${props => Math.floor(props.top)}px;
  left: ${props => Math.floor(props.left)}px;
  box-sizing: border-box;
  cursor: pointer;
  visibility: ${props => props.display ? 'visible' : 'hidden'};

  ${props => props.active && `
    z-index: 20;
  `}

  ${props => !props.hover && `
    pointer-events: none;
  `}

  &:before {
    position: relative;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    border: ${props => `${props.borderWidth}px solid ${props.borderColor}`};
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 11;
  }
`;

function BBox({ x, y, width, display = true, active, height, borderWidth = 1, borderColor = 'hsla(0,96%,48%,1)', onClick, onMouseOver, onMouseOut, children }) {
  return (
    <StyledBBox
      width={width}
      height={height}
      left={x}
      top={y}
      active={active}
      hover={onMouseOver || onMouseOut || onClick}
      display={display}
      borderColor={borderColor}
      borderWidth={borderWidth}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children}
    </StyledBBox>
  );
}

export default BBox;
