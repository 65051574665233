import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWebcamDisplay } from '../../hooks/';
import { useUploadFile } from '../../components/Droparea/hooks';
import { getPredictions, setImages, setFileUploaded } from '../../store/slices/predictions';
import { getFileUploadedStatus } from '../../store/selectors/predictions';

import { StoreContext } from '../../context/StoreContext';
import Content from '../../layers/Content';

import UploadArea from './UploadArea';
import PhotoPrediction from '../../../src/templates/Default/PhotoPrediction';
import Footer from '../../layers/Footer';
import TakePhotoButton from '../../components/TakePhoto/TakePhotoButton';
import TakePhoto from '../../components/TakePhoto';
import SidebarWrap from './SidebarWrap';
import { DropareaContextProvider } from '../../components/Droparea/DropareaContext';
import VideoBody from '../../components/Video/VideoBody';
import BackgroundWrapper from './BackgroundWrapper';
import { BACKGROUND_SIZE, BACKGROUND_SPACE } from './Default';
import Error from '../../components/Error/Error';

const INPUT_NAME = "default_input";

function Main() {
  const { error } = useContext(StoreContext);

  const dispatch = useDispatch();
  const isFileUploaded = useSelector(getFileUploadedStatus);
  const [isError, setIsError] = useState(false);

  const [showWebcam, changeDisplayWebcam] = useWebcamDisplay();
  const { file, image, video, onUploadFile, inputName } = useUploadFile({
    onError: error.set
  });


  useEffect(
    () => {
      if (!file || !image) return;

      error.set('');
      setIsError(false);
      dispatch(getPredictions({ files: [file] }));

      if (!image) return;
      dispatch(setImages({
        imgLoaderName: inputName, imgSrc: image.src
      }));
    },
    [file]
  );


  useEffect(
    () => { if (!showWebcam) error.set('') },
    [showWebcam]
  );


  useEffect(
    () => {
      (video?.src || image) && dispatch(setFileUploaded())
    },
    [video?.src, image]
  )


  const onError = () => setIsError(true);


  return (
    <DropareaContextProvider name={INPUT_NAME} loading={isFileUploaded && (video ^ image && !isError)} onUploadFile={onUploadFile}>
      <BackgroundWrapper
        size={BACKGROUND_SIZE}
        space={BACKGROUND_SPACE}
        borderRadius={44}
        isImg={image && true}
      />

      <Error onError={onError} />

      <TakePhoto
        onUploadFile={onUploadFile}
        isOpen={showWebcam}
        changeWebcamStatus={changeDisplayWebcam}
      />
      <UploadArea
        isFileUploaded={isFileUploaded && (video ^ image && !isError)}
      />
      {file && !showWebcam && (
        <Content>
          {video?.src && <VideoBody videoSrc={video.src} />}
          {image && <PhotoPrediction image={image} />}
        </Content>
      )}

      <SidebarWrap image={image} />
      <Footer>
        <TakePhotoButton onClick={changeDisplayWebcam} />
      </Footer>
    </DropareaContextProvider>
  )
}

export default Main;
