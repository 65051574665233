import React from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';

import MarkIcon from './MarkIcon';
import { Box, Button, DEMO, Modal } from '@3divi/baseui';

const IconWrapper = styled.div`
  margin: 25px auto;
  margin-bottom: 0;
`;

function ReportIssueSuccess({ isOpen, onToggleModal }) {
  return (
    <ThemeProvider theme={DEMO}>
      <Modal
        fixed={435} isOpen={isOpen} close={onToggleModal}
        header={
          <IconWrapper>
            <MarkIcon size={100} />
          </IconWrapper>
        }
        title='Thank you'
        subtitle='Your report was sent successfully'
      >
        <Box pt={2}>
          <Button
            variant='outlined'
            elastic
            size="l"
            text='Continue'
            onClick={onToggleModal}
          />
        </Box>
      </Modal>
    </ThemeProvider>
  )
}



export default ReportIssueSuccess
