import React, { useCallback, useMemo, useState } from 'react';
import './App.css';

import { StoreContextProvider } from './context/StoreContext';
import { ThemeProvider } from 'styled-components';
import { DEMO_DARK } from '@3divi/baseui';
import { breakpoints } from './components/MediaQueries/consts';

import { Default, FaceSdk } from './templates/';
import GlobalStyle from './GlobalStyles';
import MediaQueries from './components/MediaQueries';


function App() {
  const [error, setError] = useState('');
  const [videoSrc, setVideoSrc] = useState(null);
  const [displayWebcam, setDisplayWebcam] = useState(false);

  const resetStore = useCallback(() => {
    setError('');
  }, []);

  const Template = useMemo(() => window.location.hostname.includes('face.demo') ? FaceSdk : Default, [window.location.hostname]);

  const contextValues = useMemo(() => ({
    error: { value: error, set: setError },
    displayWebcam: { value: displayWebcam, set: setDisplayWebcam },
    videoSrc: { value: videoSrc, setVideoSrc },
    resetStore,
    setError,
  }), [error, videoSrc, displayWebcam]);

  return (
    <ThemeProvider theme={DEMO_DARK}>
      <StoreContextProvider value={contextValues}>
        <GlobalStyle />
        <MediaQueries breakpoints={breakpoints}>
          <Template />
        </MediaQueries>
      </StoreContextProvider>
    </ThemeProvider>
  );
}

export default App;
