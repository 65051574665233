import React, { useMemo } from 'react';
import { capitalize, floatToPercent } from '../../utils';

import { Box, Divider, FACEMACHINE_THEME, Typography } from '@3divi/baseui';
import List, { ListItem } from '../../components/List';

const DEFAULT_FIELDS = ['gender', 'age', 'liveness'];

function ListFaceMeta({ face }) {
  const maxEmotion = useMemo(() => {
    if (!face || !face.face_meta) return null;
    const maxConfidence = Math.max(...face.face_meta.emotions.map(emotion => emotion.confidence));
    return face.face_meta.emotions.find((el) => el.confidence === maxConfidence);
  }, [face]);

  return (
    <Typography color={FACEMACHINE_THEME.text.defaultContrast}>
      <List indent={0.5}>
        <ListItem padding={1}>
          <span>Confidence</span>
          <span>{face.confidence ? `${floatToPercent(face.confidence)}%` : '—'}</span>
        </ListItem>
      </List>
      {face.face_meta && (
        <>
          <Box py={1.5}>
            <Divider />
          </Box>
          <List indent={0.5}>
            {DEFAULT_FIELDS.map(name => (
              <ListItem padding={1}>
                {capitalize(face.face_meta[name].value)}
              </ListItem>
            ))}
            <ListItem padding={1}>
              {face.face_meta.mask.value ? "With a mask" : "Without a mask"}
            </ListItem>
          </List>
          <Box py={1.5}>
            <Divider />
          </Box>
          <List indent={0.5}>
            {face.face_meta.emotions.map(emotion => (
              <Typography width="100" color={maxEmotion.value === emotion.value ? "hsla(120,50%,66%,1)" : FACEMACHINE_THEME.text.defaultContrast}>
                <ListItem padding={1}>
                  <span>{capitalize(emotion.value)}</span>
                  <span>{floatToPercent(emotion.confidence)}%</span>
                </ListItem>
              </Typography>
            ))}
          </List>
        </>
      )}
    </Typography>
  )
}

export default ListFaceMeta;
