const simpleColorGenerator = (opts) => {
  const self = {}
  self.opts = opts ? opts : {};

  if (!('count' in self.opts)) self.opts.count = 10

  const hasSeed = ('seed' in self.opts)
  if (!hasSeed) self.opts.seed = Math.random()

  var delta = 1. / self.opts.count
  var hue01 = self.opts.seed * delta

  self.hues = []
  for (var i = 0; i < self.opts.count; i++) {
    self.hues.push(hue01 * 360)
    hue01 += delta
  }

  self.getColors = function (opts) {
    if (!(opts)) opts = {}
    if (!('saturation' in opts)) opts.saturation = '50%'
    if (!('lightness' in opts)) opts.lightness = '50%'
    if (!('alpha' in opts)) opts.alpha = 1.

    var colors = []
    for (var i in self.hues) {
      colors.push(getColor(self.hues[i], opts.saturation, opts.lightness, opts.alpha));
    }
    return colors;
  }

  function getColor(h, s, l, a) {
    return "hsla(" + Math.floor(h) + "," + s + "," + l + "," + a + ")";
  }

  return self;
};

export default simpleColorGenerator;