export const TYPES_OF_SEGMENTATION = {
  semantic: "semantic",
  instance: "instance",
}

const useTypeOfSegmentation = (objects) => {
  // semantic if each segment belongs to objects with the same classes
  if (!objects) return null;

  let segmentsMap = {}; // map segment id to set of object classes
  objects.forEach(obj => {
    obj.segments && obj.segments.forEach(segment => {
      if (segmentsMap[segment] !== undefined)
        segmentsMap[segment].add(obj.class);
      else
        segmentsMap[segment] = new Set([obj.class]);
    });
  });

  if (Object.keys(segmentsMap).length === 0) return null;

  const oneClassForSegment = Object.entries(segmentsMap).every(([k, v]) => v.size === 1);
  return oneClassForSegment ? TYPES_OF_SEGMENTATION.semantic : TYPES_OF_SEGMENTATION.instance;
}

export default useTypeOfSegmentation;
