import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  displayedData: {
    "bbox": true,
    "mask": true,
    "age_gender": true,
    "emotions": true,
    "liveness": true,
    "keypoints": false,
    "angles": true,
  },
}

export const faceMetaSlice = createSlice({
  name: "face_meta",
  initialState,
  reducers: {
    toggleFaceMeta: (state, action) => {
      const { name } = action.payload;
      state.displayedData[name] = !state.displayedData[name];
    }
  }
})

export const { toggleFaceMeta } = faceMetaSlice.actions;

export default faceMetaSlice.reducer;