import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPredictions } from '../../../../../../../../store/selectors/predictions';
import styled from 'styled-components';

import { Button, Typography } from '@3divi/baseui';
import { Grid } from '@material-ui/core';
import DetectedPersonList from './modules/DetectedPersonList';

const Wrapper = styled.div`
  width: 100%;
  position: ${props => props.isSmallList ? 'relative' : 'absolute'};
  bottom: 0;
  overflow: hidden;
  z-index: 99999;
`;

const Scroll = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
`;

function DetectedPersons() {
  const [isSmallList, setSmallList] = useState(true);
  const predictions = useSelector(getPredictions);
  const scrollRef = useRef(null);
  const [activeItemPositionLeft, setActiveItemPositionLeft] = useState(0);

  const toggleSmallList = useCallback(() => {
    setSmallList((prev) => !prev);
  }, []);

  useEffect(() => {
    if (scrollRef.current)
      scrollRef.current.scrollTo({ left: activeItemPositionLeft, behavior: "smooth" });
  }, [activeItemPositionLeft]);

  if (!predictions[0] || predictions[0].objects.length === 0) return null;

  return (
    <Wrapper isSmallList={isSmallList}>
      <Grid spacing={1} direction="column" container justify="center" alignItems="center">
        {isSmallList && (
          <Grid item>
            <Typography transform="uppercase" spacing={1.1} weight={600} color="secondary" size={11}>Detected {predictions[0].objects.length} persons</Typography>
          </Grid>
        )}
        <Grid xs={12} item>
          <Scroll ref={scrollRef}>
            <DetectedPersonList onSetActivePosition={setActiveItemPositionLeft} onToggleList={toggleSmallList} isSmallList={isSmallList} detectedPersons={predictions[0].objects} />
          </Scroll>
        </Grid>
        {!isSmallList && (
          <Grid item>
            <Button onClick={toggleSmallList} width="80px" size="l" borderRadius="8px" variant="contained" iconLeft="close" />
          </Grid>
        )}
      </Grid>
    </Wrapper>
  )
}

export default DetectedPersons;
