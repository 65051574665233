import { combineReducers } from 'redux';

import predictionReducer from './slices/predictions';
import faceMetaReducer from './slices/face-meta';
import trackingVideoReducer from './slices/tracking-video';

export default combineReducers({
  predictions: predictionReducer,
  faceMeta: faceMetaReducer,
  trackingVideo: trackingVideoReducer
});