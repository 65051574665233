import React from 'react';
import { Scrollbars } from 'rc-scrollbars';
import { FACEMACHINE_THEME } from '@3divi/baseui';

function ScrollBar({ children, maxHeight = 600 }) {
  return (
    <Scrollbars
      autoHeight
      autoHeightMax={maxHeight}
      renderThumbVertical={({ style, ...props }) => (
        <div {...props} style={{ ...style, ...({ background: FACEMACHINE_THEME.background.primary }) }} />
      )}
      renderTrackVertical={({ style, ...props }) => (
        <div {...props} style={{ ...style, ...({ background: "hsla(216,40%,10%,1)" }) }} />
      )}
    >
      {children}
    </Scrollbars>

  )
}

export default ScrollBar;
