export const getVideoDuration = state => state.trackingVideo.duration;
export const getPlaybackTime = state => state.trackingVideo.playbackTime;
export const getSoughtPlaybackNormal = state => state.trackingVideo.soughtPlayback;
export const getContinuousPlaybackLimit = state => state.trackingVideo.continuousPlaybackLimit;
export const getPredictionsCache = state => state.trackingVideo.cache;
export const getPlayRequestedStatus = state => state.trackingVideo.isPlayRequested;
export const getPreloadedStatus = state => state.trackingVideo.isPreloaded;
export const getSeekingStatus = state => state.trackingVideo.isSeeking;
export const getShouldPlayStatus = state => state.trackingVideo.shouldPlay;
export const getDemandForPreloadInfo = state => state.trackingVideo.demandForPreload;


export const getStateTest = state => state.trackingVideo;